// Custom.scss
// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)
$enable-validation-icons: false;

@import "../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
$track-h: 6px;
$thumb-d: 30px;

$green: #12d76c;
$lime: #b6fd6f;
$blue: #1e90ff;
$sky: #6fcafd;
$orange: #f4900c;
$yellow: #ffcc4d;
$grey: #f6f6f6;
$grey2: #eeeeee;
$dim: #8a8a8a;
$danger: #dd2e44;
$purple: #7b61ff;
$pink: #ff6187;

:root {
    --hue: 50;
    --color-alpha: hsl(var(--hue), 100%, 50%);
    --color-dark: hsla(var(--hue), 3%, 15%, 0.75);
    /* borders */
    --border-radius: 4px;
    /* box shadow */
    --box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.025), 0 1px 5px rgba(0, 0, 0, 0.25);
    /* transitions */
    --transition-duration: 0.1s;
    --transition-easing: ease-out;

    --green: #12d76c;
    --green-rgb: 18, 215, 108;
    --lime: #b6fd6f;
    --lime-rgb: 182, 253, 111;
    --blue: #1e90ff;
    --blue-rgb: 30, 144, 255;
    --sky: #6fcafd;
    --sky-light: #ecf8ff;
    --sky-rbg: 111, 202, 253;
    --orange: #f4900c;
    --orange-rgb: 244, 144, 12;
    --yellow: #ffcc4d;
    --yellow-rgb: 255, 204, 77;
    --bs-body-color: #111111;
    --grey: #f6f6f6;
    --grey2: #eeeeee;
    --dim: #8a8a8a;
    --bs-danger: #dd2e44;
    --bs-danger-rgb: 221, 46, 68;
    --purple: #7b61ff;
    --purple-rgb: 123, 97, 255;
    --bs-info: #3b88c3;
    --bs-info-rgb: 59, 136, 195;
    --pink: #ff6187;
    --pink-rgb: 255, 97, 135;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: #000;
}

::-webkit-scrollbar-track {
    border-radius: 1px;
}

::-webkit-scrollbar-thumb {
    border-radius: 1px;
    background-color: var(--orange);
    transition: 0.3s;

    &:hover {
        background-color: var(--blue);
    }
}

body {
    font-family: "DM Sans", sans-serif;
    // touch-action: pan-y !important;
}

div#root {
    margin: 0;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.body-content {
    display: block;
    flex: 1 0 auto;
}

.l {
    box-shadow: inset 0 0 0 1px red;
}

.section1::first-letter {
    font-size: 20px;
    color: #8a2be2;
}

.icon {
    stroke: unset;
    stroke-width: 0px;
    fill: currentColor;
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
}

.filer-grayscale {
    filter: grayscale(1);
}

.ts-1p1 {
    transform: scale(1.1);
}

.ts-1p2 {
    transform: scale(1.2);
}

.ts-1p3 {
    transform: scale(1.3);
}

.ts-1p4 {
    transform: scale(1.4);
}

.ts-1p5 {
    transform: scale(1.5);
}

.ts-1p6 {
    transform: scale(1.6);
}

.ts-1p7 {
    transform: scale(1.7);
}

.ts-1p8 {
    transform: scale(1.8);
}

.ts-1p9 {
    transform: scale(1.9);
}

.ts-2 {
    transform: scale(2);
}

.text-green {
    color: var(--green);
}

.text-lime {
    color: var(--lime);
}

.text-blue {
    color: var(--blue);
}

.text-sky {
    color: var(--sky);
}

.text-orange {
    color: var(--orange);
}

.text-yellow {
    color: var(--yellow);
}

.text-grey {
    color: var(--grey);
}

.text-grey2 {
    color: var(--grey2);
}

.text-dim {
    color: var(--dim);
}

.text-purple {
    color: var(--purple);
}

.text-pink {
    color: var(--pink);
}

.bg-green {
    background-color: var(--green);
}

.bg-green-imp {
    background-color: var(--green) !important;
}

.bg-lime {
    background-color: var(--lime);
}

.bg-blue {
    background-color: var(--blue);
}

.bg-sky {
    background-color: var(--sky);
}

.bg-sky-light {
    background-color: var(--sky-light);
}

.bg-orange {
    background-color: var(--orange);
}

.bg-yellow {
    background-color: var(--yellow);
}

.bg-grey {
    background-color: var(--grey);
}

.bg-grey2 {
    background-color: var(--grey2);
}

.bg-dim {
    background-color: var(--dim);
}

.bg-purple {
    background-color: var(--purple);
}

.bg-pink {
    background-color: var(--pink);
}

.bg-gradient1 {
    background: linear-gradient(245deg, #1e90ff -1.64%, #12d76c 100%);
}

.img-cover {
    object-fit: cover;
}

.opacity-55 {
    opacity: 0.55;
}

.opacity-60 {
    opacity: 0.6;
}

.opacity-65 {
    opacity: 0.65;
}

.container-1040 {
    max-width: 1040px;
}

.container-1180 {
    max-width: 1180px;
}

.fw-medium {
    font-weight: 500;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-17 {
    font-size: 17px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-32 {
    font-size: 32px !important;
}

.fs-36 {
    font-size: 36px !important;
}

.fa-1x {
    font-size: 1em !important;
}

.fa-2x {
    font-size: 2em !important;
}

.fa-3x {
    font-size: 3em !important;
}

.fa-4x {
    font-size: 4em !important;
}

.fa-5x {
    font-size: 5em !important;
}

.fa-6x {
    font-size: 6em !important;
}

.fa-7x {
    font-size: 7em !important;
}

.fa-8x {
    font-size: 8em !important;
}

.fa-9x {
    font-size: 9em !important;
}

.fa-10x {
    font-size: 10em !important;
}

.p-20 {
    padding: 20px;
}

.p-25 {
    padding: 25px;
}

.pb-17px {
    padding-bottom: 17px;
}

.pse-10px {
    padding: 0 10px 0 10px;
}

.mmt-10 {
    margin-top: -10px;
}

.mmt-20 {
    margin-top: -20px;
}

.mmt-30 {
    margin-top: -30px;
}

.mmt-40 {
    margin-top: -40px;
}

.mt-13 {
    margin-top: 13px;
}

.mt-14 {
    margin-top: 14px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-17 {
    margin-top: 17px;
}

.mt-18 {
    margin-top: 18px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-22 {
    margin-top: 22px;
}

.mt-23 {
    margin-top: 23px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-26 {
    margin-top: 26px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-32 {
    margin-top: 32px;
}

.mt-35 {
    margin-top: 35px;
}

.mt-40 {
    margin-top: 40px;
}

.mms-20 {
    margin-left: -20px;
}

.mms-30 {
    margin-left: -30px;
}

.mms-40 {
    margin-left: -40px;
}

.mms-50 {
    margin-left: -50px;
}

.mms-60 {
    margin-left: -60px;
}

.mms-70 {
    margin-left: -70px;
}

.mme-10 {
    margin-right: -10px;
}

.mme-20 {
    margin-right: -20px;
}

.mme-30 {
    margin-right: -30px;
}

.mme-40 {
    margin-right: -40px;
}

.mmb-10 {
    margin-bottom: -10px;
}

.mmb-15 {
    margin-bottom: -15px;
}

.mmb-20 {
    margin-bottom: -20px;
}

.mmb-25 {
    margin-bottom: -25px;
}

.mmb-30 {
    margin-bottom: -30px;
}

.mmb-40 {
    margin-bottom: -40px;
}

.minw-30 {
    min-width: 30px;
}

.minw-40 {
    min-width: 40px;
}

.minw-45 {
    min-width: 45px;
}

.minw-50 {
    min-width: 50px;
}

.minw-70 {
    min-width: 70px;
}

.minw-75 {
    min-width: 75px;
}

.minw-90 {
    min-width: 90px;
}

.minw-100 {
    min-width: 100px;
}

.minw-110 {
    min-width: 110px;
}

.minw-120 {
    min-width: 120px;
}

.minw-130 {
    min-width: 130px;
}

.minw-160 {
    min-width: 160px;
}

.minw-170 {
    min-width: 170px;
}

.minw-180 {
    min-width: 180px;
}

.minw-190 {
    min-width: 190px;
}

.minw-200 {
    min-width: 200px;
}

.minh-100p {
    min-height: 100%;
}

.minh-20 {
    min-height: 20px;
}

.minh-44 {
    min-height: 44px;
}

.minh-48 {
    min-height: 48px;
}

.minh-50 {
    min-height: 50px;
}

.minh-52 {
    min-height: 52px;
}

.minh-100 {
    min-height: 100px;
}

.minh-120 {
    min-height: 120px;
}

.mmwh-18 {
    min-width: 18px !important;
    max-width: 18px;
    min-height: 18px !important;
    max-height: 18px !important;
}

.mmwh-20 {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
}

.mmwh-24 {
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
}

.mmwh-35 {
    min-width: 35px;
    max-width: 35px;
    min-height: 35px;
    max-height: 35px;
}

.mmwh-36 {
    min-width: 36px;
    max-width: 36px;
    min-height: 36px;
    max-height: 36px;
}

.mmwh-44 {
    min-width: 44px;
    max-width: 44px;
    min-height: 44px;
    max-height: 44px;
}

.mmwh-48 {
    min-width: 48px;
    max-width: 48px;
    min-height: 48px;
    max-height: 48px;
}

.mmwh-56 {
    min-width: 56px;
    max-width: 56px;
    min-height: 56px;
    max-height: 56px;
}

.mmwhcms-100 {
    min-width: 97px;
    max-width: 97px;
    min-height: 97px;
    max-height: 97px;
}

.mmwh-150 {
    min-width: 150px;
    max-width: 150px;
    min-height: 150px;
    max-height: 150px;
}

.mmwh-160 {
    min-width: 160px;
    max-width: 160px;
    min-height: 160px;
    max-height: 160px;
}

.mmwh-164 {
    min-width: 164px;
    max-width: 164px;
    min-height: 164px;
    max-height: 164px;
}

.mmwh-180 {
    min-width: 180px;
    max-width: 180px;
    min-height: 180px;
    max-height: 180px;
}

.mmwh-200 {
    min-width: 200px;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
}

.mmwh-215 {
    min-width: 215px;
    max-width: 215px;
    min-height: 215px;
    max-height: 215px;
}

.mmwh-250 {
    min-width: 250px;
    max-width: 250px;
    min-height: 250px;
    max-height: 250px;
}

.mmwh-260 {
    min-width: 260px;
    max-width: 260px;
    min-height: 260px;
    max-height: 260px;
}

.maxw-70 {
    max-width: 70px;
}

.maxw-75 {
    max-width: 75px;
}

.maxw-80 {
    max-width: 80px;
}

.maxw-90 {
    max-width: 90px;
}

.maxw-95 {
    max-width: 95px;
}

.maxw-100 {
    max-width: 100px;
}

.maxw-110 {
    max-width: 110px;
}

.maxw-120 {
    max-width: 120px;
}

.maxw-130 {
    max-width: 130px;
}

.maxw-140 {
    max-width: 140px;
}

.maxw-150 {
    max-width: 150px;
}

.maxw-160 {
    max-width: 160px;
}

.maxw-180 {
    max-width: 180px;
}

.maxw-200 {
    max-width: 200px;
}

.maxw-210 {
    max-width: 210px;
}

.maxw-215 {
    max-width: 215px;
}

.maxw-220 {
    max-width: 220px;
}

.maxw-240 {
    max-width: 240px;
}

.maxw-250 {
    max-width: 250px;
}

.maxw-260 {
    max-width: 260px;
}

.maxw-270 {
    max-width: 270px;
}

.maxw-280 {
    max-width: 280px;
}

.maxw-300 {
    max-width: 300px;
}

.maxw-310 {
    max-width: 310px;
}

.maxw-320 {
    max-width: 320px;
}

.maxw-350 {
    max-width: 350px;
}

.maxw-360 {
    max-width: 360px;
}

.maxw-380 {
    max-width: 380px;
}

.maxw-400 {
    max-width: 400px;
}

.maxw-420 {
    max-width: 420px;
}

.maxw-450 {
    max-width: 450px;
}

.maxw-480 {
    max-width: 480px;
}

.maxw-500 {
    max-width: 500px;
}

.maxw-540 {
    max-width: 540px;
}

.maxw-550 {
    max-width: 550px;
}

.maxw-560 {
    max-width: 560px;
}

.maxw-570 {
    max-width: 570px;
}

.maxw-580 {
    max-width: 580px;
}

.maxw-600 {
    max-width: 600px;
}

.maxw-620 {
    max-width: 620px;
}

.maxw-640 {
    max-width: 640px;
}

.maxw-650 {
    max-width: 650px;
}

.maxw-660 {
    max-width: 660px;
}

.maxw-680 {
    max-width: 680px;
}

.maxw-700 {
    max-width: 700px;
}

.maxw-720 {
    max-width: 720px;
}

.maxw-800 {
    max-width: 800px;
}

.maxw-820 {
    max-width: 820px;
}

.maxw-880 {
    max-width: 880px;
}

.maxw-900 {
    max-width: 900px;
}

.mmw-56 {
    min-width: 56px;
    max-width: 56px;
}

.mmw-120 {
    min-width: 120px;
    max-width: 120px;
}

.mmw-130 {
    min-width: 130px;
    max-width: 130px;
}

.mmw-300 {
    min-width: 300px;
    max-width: 300px;
}

.mmw-320 {
    min-width: 320px;
    max-width: 320px;
}

.w-150pp {
    width: 151% !important;
}

.h-90 {
    height: 90px !important;
}

.h-110 {
    height: 110px !important;
}

.h-120 {
    height: 120px !important;
}

.h-140 {
    height: 140px !important;
}

.h-150 {
    height: 150px !important;
}

.h-200 {
    height: 200px !important;
}

.h-220 {
    height: 220px !important;
}

.h-240 {
    height: 240px !important;
}

.h-250 {
    height: 250px !important;
}

.h-260 {
    height: 260px !important;
}

.h-280 {
    height: 280px !important;
}

.h-300 {
    height: 300px !important;
}

.h-750 {
    height: 750px !important;
}

.h-800 {
    height: 800px !important;
}

.zindex-minus {
    z-index: -1;
}

.zindex-0 {
    z-index: 0;
}

.zindex-1 {
    z-index: 1;
}

.zindex-10 {
    z-index: 10;
}

.zindex-20 {
    z-index: 20;
}

.zindex-100 {
    z-index: 100;
}

.zindex-2000 {
    z-index: 2000;
}

.zindex-header-overlaping {
    z-index: 1111;
}

/** only home tooltip**/
.popover {

    // z-index: 11;
    .popover-inner .col-sm-8 img {
        max-width: 100px;
        height: 102px;
        object-fit: cover;
    }

}

/** only home tooltip **/
.border-sky {
    border-color: var(--sky) !important;
}

.border-blue {
    border-color: var(--blue) !important;
}

.border-green {
    border-color: var(--green) !important;
}

.border-lime {
    border-color: var(--lime) !important;
}

.border-dim {
    border-color: var(--dim) !important;
}

.border-yellow {
    border-color: var(--yellow) !important;
}

.border-purple {
    border-color: var(--purple) !important;
}

.filer-grayscale {
    filter: grayscale(1);
}

.event-none {
    pointer-events: none;

    &::after {
        display: none;
    }
}

.ts-1p1 {
    transform: scale(1.1);
}

.ts-1p2 {
    transform: scale(1.2);
}

.ts-1p3 {
    transform: scale(1.3);
}

.ts-1p4 {
    transform: scale(1.4);
}

.ts-1p5 {
    transform: scale(1.5);
}

.ts-1p6 {
    transform: scale(1.6);
}

.ts-1p7 {
    transform: scale(1.7);
}

.ts-1p8 {
    transform: scale(1.8);
}

.ts-1p9 {
    transform: scale(1.9);
}

.ts-2 {
    transform: scale(2);
}

.text-green {
    color: var(--green);
}

.text-lime {
    color: var(--lime);
}

.text-blue {
    color: var(--blue);
}

.text-sky {
    color: var(--sky);
}

.text-orange {
    color: var(--orange);
}

.text-yellow {
    color: var(--yellow);
}

.text-grey {
    color: var(--grey);
}

.text-grey2 {
    color: var(--grey2);
}

.text-dim {
    color: var(--dim);
}

.text-purple {
    color: var(--purple);
}

.text-pink {
    color: var(--pink);
}

.bg-green {
    background-color: var(--green);
}

.bg-lime {
    background-color: var(--lime);
}

.bg-blue {
    background-color: var(--blue);
}

.bg-sky {
    background-color: var(--sky);
}

.bg-orange {
    background-color: var(--orange);
}

.bg-yellow {
    background-color: var(--yellow);
}

.bg-grey {
    background-color: var(--grey);
}

.bg-grey2 {
    background-color: var(--grey2);
}

.bg-dim {
    background-color: var(--dim);
}

.bg-purple {
    background-color: var(--purple);
}

.bg-pink {
    background-color: var(--pink);
}

.bg-gradient1 {
    background: linear-gradient(245deg, #1e90ff -1.64%, #12d76c 100%);
}

.img-cover {
    object-fit: cover;
}

.opacity-55 {
    opacity: 0.55;
}

.opacity-60 {
    opacity: 0.6;
}

.opacity-65 {
    opacity: 0.65;
}

.container-1040 {
    max-width: 1040px;
}

.container-1180 {
    max-width: 1180px;
}

.fw-medium {
    font-weight: 500;
}

.fw-unset {
    font-weight: unset;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-17 {
    font-size: 17px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-32 {
    font-size: 32px !important;
}

.fs-36 {
    font-size: 36px !important;
}

.fa-1x {
    font-size: 1em !important;
}

.fa-2x {
    font-size: 2em !important;
}

.fa-3x {
    font-size: 3em !important;
}

.fa-4x {
    font-size: 4em !important;
}

.fa-5x {
    font-size: 5em !important;
}

.fa-6x {
    font-size: 6em !important;
}

.fa-7x {
    font-size: 7em !important;
}

.fa-8x {
    font-size: 8em !important;
}

.fa-9x {
    font-size: 9em !important;
}

.fa-10x {
    font-size: 10em !important;
}

.p-20 {
    padding: 20px;
}

.p-25 {
    padding: 25px;
}

.pb-17px {
    padding-bottom: 17px;
}

.mmt-10 {
    margin-top: -10px;
}

.mmt-20 {
    margin-top: -20px;
}

.mmt-30 {
    margin-top: -30px;
}

.mmt-40 {
    margin-top: -40px;
}

.mt-13 {
    margin-top: 13px;
}

.mt-14 {
    margin-top: 14px;
}

.mt-15 {
    margin-top: 15px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-17 {
    margin-top: 17px;
}

.mt-18 {
    margin-top: 18px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-22 {
    margin-top: 22px;
}

.mt-23 {
    margin-top: 23px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-25 {
    margin-top: 25px;
}

.mt-26 {
    margin-top: 26px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-32 {
    margin-top: 32px;
}

.mt-35 {
    margin-top: 35px;
}

.mt-40 {
    margin-top: 40px;
}

.mms-20 {
    margin-left: -20px;
}

.mms-30 {
    margin-left: -30px;
}

.mms-40 {
    margin-left: -40px;
}

.mms-50 {
    margin-left: -50px;
}

.mms-60 {
    margin-left: -60px;
}

.mms-70 {
    margin-left: -70px;
}

.mme-10 {
    margin-right: -10px;
}

.mme-20 {
    margin-right: -20px;
}

.mme-30 {
    margin-right: -30px;
}

.mme-40 {
    margin-right: -40px;
}

.mmb-10 {
    margin-bottom: -10px;
}

.mmb-15 {
    margin-bottom: -15px;
}

.mmb-20 {
    margin-bottom: -20px;
}

.mmb-25 {
    margin-bottom: -25px;
}

.mmb-30 {
    margin-bottom: -30px;
}

.mmb-40 {
    margin-bottom: -40px;
}

.minw-30 {
    min-width: 30px;
}

.minw-40 {
    min-width: 40px;
}

.minw-45 {
    min-width: 45px;
}

.minw-50 {
    min-width: 50px;
}

.minw-70 {
    min-width: 70px;
}

.minw-75 {
    min-width: 75px;
}

.minw-90 {
    min-width: 90px;
}

.minw-100 {
    min-width: 100px;
}

.minw-110 {
    min-width: 110px;
}

.minw-120 {
    min-width: 120px;
}

.minw-130 {
    min-width: 130px;
}

.minw-160 {
    min-width: 160px;
}

.minw-170 {
    min-width: 170px;
}

.minw-180 {
    min-width: 180px;
}

.minw-190 {
    min-width: 190px;
}

.minw-200 {
    min-width: 200px;
}

.minh-100p {
    min-height: 100%;
}

.minh-20 {
    min-height: 20px;
}

.minh-40 {
    min-height: 40px;
}

.minh-44 {
    min-height: 44px;
}

.minh-48 {
    min-height: 48px;
}

.minh-50 {
    min-height: 50px;
}

.minh-52 {
    min-height: 52px;
}

.minh-100 {
    min-height: 100px;
}

.minh-120 {
    min-height: 120px;
}

.mmwh-18 {
    min-width: 18px !important;
    max-width: 18px;
    min-height: 18px !important;
    max-height: 18px !important;
}

.mmwh-20 {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
}

.mmwh-24 {
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
}

.mmwh-35 {
    min-width: 35px;
    max-width: 35px;
    min-height: 35px;
    max-height: 35px;
}

.mmwh-36 {
    min-width: 36px;
    max-width: 36px;
    min-height: 36px;
    max-height: 36px;
}

.mmwh-44 {
    min-width: 44px;
    max-width: 44px;
    min-height: 44px;
    max-height: 44px;
}

.mmwh-48 {
    min-width: 48px;
    max-width: 48px;
    min-height: 48px;
    max-height: 48px;
}

.mmwh-56 {
    min-width: 56px;
    max-width: 56px;
    min-height: 56px;
    max-height: 56px;
}

.mmwh-150 {
    min-width: 150px;
    max-width: 150px;
    min-height: 150px;
    max-height: 150px;
}

.mmwh-160 {
    min-width: 160px;
    max-width: 160px;
    min-height: 160px;
    max-height: 160px;
}

.mmwh-164 {
    min-width: 164px;
    max-width: 164px;
    min-height: 164px;
    max-height: 164px;
}

.mmwh-180 {
    min-width: 180px;
    max-width: 180px;
    min-height: 180px;
    max-height: 180px;
}

.mmwh-200 {
    min-width: 200px;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
}

.mmwh-215 {
    min-width: 215px;
    max-width: 215px;
    min-height: 215px;
    max-height: 215px;
}

.mmwh-250 {
    min-width: 250px;
    max-width: 250px;
    min-height: 250px;
    max-height: 250px;
}

.mmwh-260 {
    min-width: 260px;
    max-width: 260px;
    min-height: 260px;
    max-height: 260px;
}

.maxw-70 {
    max-width: 70px;
}

.maxw-75 {
    max-width: 75px;
}

.maxw-80 {
    max-width: 80px;
}

.maxw-90 {
    max-width: 90px;
}

.maxw-95 {
    max-width: 95px;
}

.maxw-100 {
    max-width: 100px;
}

.maxw-110 {
    max-width: 110px;
}

.maxw-120 {
    max-width: 120px;
}

.maxw-130 {
    max-width: 130px;
}

.maxw-140 {
    max-width: 140px;
}

.maxw-150 {
    max-width: 150px;
}

.maxw-160 {
    max-width: 160px;
}

.maxw-180 {
    max-width: 180px;
}

.maxw-200 {
    max-width: 200px;
}

.maxw-210 {
    max-width: 210px;
}

.maxw-215 {
    max-width: 215px;
}

.maxw-220 {
    max-width: 220px;
}

.maxw-240 {
    max-width: 240px;
}

.maxw-250 {
    max-width: 250px;
}

.maxw-260 {
    max-width: 260px;
}

.maxw-270 {
    max-width: 270px;
}

.maxw-280 {
    max-width: 280px;
}

.maxw-300 {
    max-width: 300px;
}

.maxw-310 {
    max-width: 310px;
}

.maxw-320 {
    max-width: 320px;
}

.maxw-350 {
    max-width: 350px;
}

.maxw-360 {
    max-width: 360px;
}

.maxw-380 {
    max-width: 380px;
}

.maxw-400 {
    max-width: 400px;
}

.maxw-420 {
    max-width: 420px;
}

.maxw-450 {
    max-width: 450px;
}

.maxw-480 {
    max-width: 480px;
}

.maxw-500 {
    max-width: 500px;
}

.maxw-540 {
    max-width: 540px;
}

.maxw-550 {
    max-width: 550px;
}

.maxw-560 {
    max-width: 560px;
}

.maxw-570 {
    max-width: 570px;
}

.maxw-580 {
    max-width: 580px;
}

.maxw-600 {
    max-width: 600px;
}

.maxw-620 {
    max-width: 620px;
}

.maxw-640 {
    max-width: 640px;
}

.maxw-650 {
    max-width: 650px;
}

.maxw-660 {
    max-width: 660px;
}

.maxw-680 {
    max-width: 680px;
}

.maxw-700 {
    max-width: 700px;
}

.maxw-720 {
    max-width: 720px;
}

.maxw-800 {
    max-width: 800px;
}

.maxw-820 {
    max-width: 820px;
}

.maxw-880 {
    max-width: 880px;
}

.maxw-900 {
    max-width: 900px;
}

.mmw-56 {
    min-width: 56px;
    max-width: 56px;
}

.mmw-120 {
    min-width: 120px;
    max-width: 120px;
}

.mmw-130 {
    min-width: 130px;
    max-width: 130px;
}

.mmw-300 {
    min-width: 300px;
    max-width: 300px;
}

.mmw-320 {
    min-width: 320px;
    max-width: 320px;
}

.h-90 {
    height: 90px !important;
}

.h-110 {
    height: 110px !important;
}

.h-120 {
    height: 120px !important;
}

.h-140 {
    height: 140px !important;
}

.h-150 {
    height: 150px !important;
}

.h-200 {
    height: 200px !important;
}

.h-220 {
    height: 220px !important;
}

.h-240 {
    height: 240px !important;
}

.h-250 {
    height: 250px !important;
}

.h-260 {
    height: 260px !important;
}

.h-280 {
    height: 280px !important;
}

.h-300 {
    height: 300px !important;
}

.zindex-0 {
    z-index: 0;
}

.zindex-1 {
    z-index: 1;
}

.zindex-10 {
    z-index: 10;
}

.zindex-20 {
    z-index: 20;
}

.zindex-100 {
    z-index: 100;
}

.border-sky {
    border-color: var(--sky) !important;
}

.border-blue {
    border-color: var(--blue) !important;
}

.border-green {
    border-color: var(--green) !important;
}

.border-lime {
    border-color: var(--lime) !important;
}

.border-dim {
    border-color: var(--dim) !important;
}

.border-yellow {
    border-color: var(--yellow) !important;
}

.border-purple {
    border-color: var(--purple) !important;
}

.border-current {
    border-color: currentColor !important;
}

.border-blue-hover {
    &:hover {
        border-color: var(--blue) !important;
        box-shadow: 0 0 0 1px var(--blue) !important;
    }
}

.ttym-20 {
    transform: translateY(-20px);
}

.ttym-25 {
    transform: translateY(-25px);
}

.ttym-30 {
    transform: translateY(-30px);
}

.ttym-40 {
    transform: translateY(-40px);
}

.ttym-50 {
    transform: translateY(-50px);
}

.ttym-60 {
    transform: translateY(-60px);
}

.ttym-65 {
    transform: translateY(-65px);
}

.ttym-70 {
    transform: translateY(-70px);
}

.setpage-height {
    min-height: calc(100vh - 86px - 244px);
}

.card-box-shadow {
    box-shadow: 0 1px 2px rgb(56 65 74 / 15%);
}

.no-transition {
    transition: none !important;
}
.white-space{
    white-space: nowrap;
}
.btn-manage-width{
    max-width: 204px !important;
}
.card-text-ellipse{
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80px;
}
.card-heading-ellipse{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
}
.form-check-custom {
    padding-left: 2.25em;

    .form-check-input {
        margin-left: -2.25em;
        width: 1.5em;
        height: 1.5em;

        &:checked {
            background-color: var(--blue);
            border-color: var(--blue);
        }

        &.form-check-green {
            &:checked {
                background-color: var(--green);
                border-color: var(--green);
            }

            &:focus {
                border-color: rgba($color: $green, $alpha: 0.5);
                box-shadow: 0 0 0 0.25rem rgba($color: $green, $alpha: 0.25);
            }
        }

        &.form-check-orange {
            &:checked {
                background-color: var(--orange);
                border-color: var(--orange);
            }

            &:focus {
                border-color: rgba($color: $orange, $alpha: 0.5);
                box-shadow: 0 0 0 0.25rem rgba($color: $orange, $alpha: 0.25);
            }
        }

        &.form-check-purple {
            &:checked {
                background-color: var(--purple);
                border-color: var(--purple);
            }

            &:focus {
                border-color: rgba($color: $purple, $alpha: 0.5);
                box-shadow: 0 0 0 0.25rem rgba($color: $purple, $alpha: 0.25);
            }
        }
    }

    .form-check-label {
        padding-top: 4px;
    }
}

.form-select {
    -webkit-appearance: none !important;
    appearance: none !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 10 11%27%3e%3cpath fill=%27%23BFBFBF%27 d=%27M10 5.5L9.11875 4.61875L5.625 8.10625V0.5H4.375V8.10625L0.8875 4.6125L0 5.5L5 10.5L10 5.5Z%27/%3e%3c/svg%3e");

    &:focus {
        background-image: url("data:image/svg+xml,<svg fill='%23F4900C' viewBox='0 0 10 11' xmlns='http://www.w3.org/2000/svg'><path d='M10 5.5L9.11875 4.61875L5.625 8.10625V0.5H4.375V8.10625L0.8875 4.6125L0 5.5L5 10.5L10 5.5Z'/></svg>");
    }

    &.maxw-25p {
        width: unset !important;
        max-width: 25%;

    }
}

a {
    color: var(--blue);
}

.blue-hover {

    &:hover *,
    &:hover {
        color: var(--blue) !important;
    }
}

.breadcrumb {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;

    .breadcrumb-item+.breadcrumb-item {
        &::before {
            content: ">";
        }
    }

    a {
        text-decoration: none;
    }
}

.shadow-md {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.circle-progress {
    path:nth-child(1) {
        fill: none;
    }

    path:nth-child(2) {
        fill: none;
        stroke-linecap: round;
        transition: all 300ms ease-out;
    }

    text {
        font-family: "DM Sans", sans-serif;
        font-weight: 900;
        font-size: 62%;
        text-align: center;
        text-anchor: middle;
        transition: all 300ms ease-out;
        user-select: none;
    }
}

/*Start | Front Header Style*/
.navbar-front {
    padding-top: 2px;
    padding-bottom: 2px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

    .navbar-brand {
        img {
            max-width: 100%;
            max-height: 72px;
            min-height: 72px;
        }
    }

    .navbar-toggler {
        width: 44px;
        height: 44px;
        padding: 8px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid var(--blue);

        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%231E90FF' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        }
    }

    .navbar-nav {
        .nav-item {
            margin-left: 30px;

            .nav-link {
                font-weight: 700;
                color: var(--blue);

                &:hover {
                    color: var(--sky);
                }

                &.active,
                &.show {
                    color: var(--green);
                }
            }

            &.dropdown-user {
                .dropdown-toggle {
                    width: 42px;
                    height: 42px;
                    font-size: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    color: #fff;
                    background-color: var(--sky);
                    box-shadow: 0 0 0 1px var(--blue);

                    &::after {
                        display: none;
                    }

                    &.show {
                        background-color: var(--blue);
                    }
                }
            }

            .cart-nav-link span {
                position: absolute;
                top: 0;
                right: 0;
                // width: 15px;
                // height: 15px;
                // font-size: 11px;
                // text-align: center;
                width: 17px;
                height: 17px;
                font-size: 10px;
                text-align: center;
                display: flex;
                padding: 4px 4px 5px 4px;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

@media (max-width: 991.9px) {
    .navbar-front {
        .navbar-nav {
            .nav-item {
                margin-left: 10px;
                margin-right: 10px;

                &.dropdown-user {
                    // border-top: 1px solid #111;
                    padding-top: 22px;
                    margin-top: 12px;
                    margin-bottom: 22px;
                }

                &:nth-last-child(2) {
                    // padding-top: 22px;
                    // border-top: 1px solid #111;
                }
            }
        }
    }
}

@media (max-width: 420px) {
    .navbar-front {
        .navbar-brand {
            img {
                height: 50px;
                min-height: 50px;
            }
        }
    }
}

/*End | Front Header Style*/

.body-overflowhidden {
    overflow: hidden !important;
}

.home-section1 {
    position: relative;
    overflow: hidden;
    padding-top: 100px;
    padding-bottom: 120px;

    .homebg-cover {
        position: absolute;
        width: 120%;
        height: 200%;
        bottom: -120px;
        left: -10%;
        z-index: 5;
        user-select: none;
        object-position: center;
        pointer-events: none;
    }
}

.hey-man {
    img {
        position: relative;
        z-index: 1;
    }

    .circle1 {
        width: 100%;
        max-width: 60%;
        border-radius: 100%;
        aspect-ratio: 1 / 1;
        background-color: var(--sky);
        position: absolute;
        z-index: 1;
        top: 20%;
        left: 18%;
    }

    .circle2 {
        width: 100%;
        max-width: 30%;
        border-radius: 100%;
        aspect-ratio: 1 / 1;
        background-color: var(--green);
        position: absolute;
        z-index: 0;
        top: 24%;
        right: 8%;
    }
}

.home-section2 {
    overflow: hidden;
}

.card-dragging {
    border: 2px solid var(--blue);
    border-radius: 16px;
}

.query-shape {
    padding-left: 52px;
    position: relative;

    .shape1 {
        width: 240px;
        height: 240px;
        background-color: var(--blue);
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        left: -120px;

        &::after {
            content: "";
            position: absolute;
            width: 200%;
            height: 50%;
            left: 25%;
            bottom: 0;
            background-color: #fff;
            border-radius: 100px;
        }
    }

    .shape2 {
        width: 450px;
        height: 140px;
        background-color: var(--sky);
        border-radius: 100px;
        position: absolute;
        top: -50px;
        right: -150px;
        z-index: 0;

        &::before {
            content: "";
            position: absolute;
            width: 100px;
            height: 100px;
            right: 17%;
            bottom: -99px;
            background-color: var(--blue);
            border-radius: 0 100px 100px 100px;
            z-index: 0;
        }
    }
}

.password-wrapper {
    position: relative;

    input {
        padding-right: 50px;
    }

    button {
        position: absolute;
        padding: 1px 6px;
        top: 50%;
        right: 2px;
        z-index: 2;
        border: none;
        font-size: 140%;
        transform: translateY(-50%);
        color: var(--dim);

        &:hover {
            color: var(--blue);
        }
    }
}

.home-section3 {
    position: relative;
    overflow: hidden;
    padding-top: 100px;
    padding-bottom: 120px;

    .homebg-cover {
        position: absolute;
        width: 120%;
        height: calc(100% - 20px);
        top: 10px;
        left: -10%;
        z-index: 0;
        user-select: none;
        object-position: center;
    }
}

.practice-exam {
    padding: 20px 0;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .circle {
        position: absolute;
        width: 140px;
        height: 140px;
        border-radius: 200px;
        display: block;
        left: 200px;
        top: 1px;
        background-color: var(--orange);
    }

    .pill {
        position: absolute;
        width: 200px;
        height: 80px;
        border-radius: 200px;
        display: block;
        left: 0;
        bottom: 35px;
        background-color: var(--yellow);
    }

    .pe-card {
        width: 200px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 23px 24px 24px;
        border: 1px solid #1e90ff;
        box-shadow: 0px 4px 0px #85b9e3;
        border-radius: 16px;
        z-index: 2;

        &.pr-cardend {
            margin-left: 13px;
            margin-top: 70px;
        }

        img {
            width: 48px;
            height: 48px;
        }

        h4 {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 700;
            margin: 10px 0 4px 0;
        }

        p {
            font-size: 14px;
            margin: 0;
        }
    }
}

.triangle {
    width: 50px;
    height: 50px;
    position: absolute;
    transform: rotate(316deg);
    left: 94%;
    top: 15%;
    z-index: -1;
}

.home-section4 {
    a {
        text-decoration: none;
    }
}

@media (max-width: 768px) {
    .container-1040 {
        --bs-gutter-x: 3rem;
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
    }

    .home-section3 {
        padding-top: 100px;
        padding-bottom: 120px;

        .homebg-cover {
            position: absolute;
            width: 300%;
            height: calc(100% - 20px);
            top: 10px;
            left: -120%;
            z-index: 0;
            user-select: none;
            object-position: center;
        }
    }
}

@media (max-width: 480px) {
    .practice-exam {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;

        .circle {
            left: 180px;
        }

        .pill {
            width: 80px;
            height: 200px;
            left: 64px;
            bottom: 100px;
        }

        .pe-card {
            min-width: 200px;

            &.pr-cardend {
                margin-left: 111px;
                margin-top: 17px;
            }
        }
    }
}

@media (max-width: 360px) {
    .practice-exam {
        .circle {
            left: auto;
            right: 10px;
        }
    }

    .overlay-bgbl-exam {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        border-radius: 0;
    }
}

.card-workbook {
    display: block;
    width: 100%;
    max-width: 198px;
    overflow: hidden;
    // filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.2));
    border-radius: 8px;
    box-shadow: 0 0 2px 1px rgb(42 42 42 / 12%);
}

.query-place {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 120px;
    min-height: 45px;
    border: 2px dashed #bababa;
    border-radius: 8px;
    vertical-align: middle;
    position: relative;

    &:empty::after {
        content: "?";
        color: #bababa;
        font-weight: 500;
    }

    &.half-drag {
        border-radius: 0 8px 8px 0;
        min-width: 110px;
    }

    &.multi-drop {
        min-height: 125px;
        padding: 5px;
        gap: 4px;
        display: grid;
    }

    [draggable="true"] {
        border: none;
        box-shadow: none;
        pointer-events: none;

        &::after {
            display: none;
        }

        .icon {
            display: none;
        }
    }
}

.home-section5 {
    position: relative;
    overflow: hidden;
    padding-top: 120px;
    padding-bottom: 120px;
    margin-top: 10px;
    margin-bottom: 50px;

    .homebg-cover {
        position: absolute;
        width: 120%;
        height: calc(100% - 20px);
        top: 10px;
        left: -10%;
        z-index: 0;
        user-select: none;
        object-position: center;
        -webkit-appearance: none
    }
}

.card-limebar {
    padding-left: 110px;
    width: 100%;
    max-width: 710px;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        width: 110px;
        height: 110px;
        background-color: var(--green);
        z-index: 0;
        border-radius: 110px;
        position: absolute;
        left: 50px;
        bottom: -40px;
    }

    .card-innerbar {
        display: flex;
        align-items: center;
        background-color: var(--lime);
        border-radius: 16px;
        overflow: hidden;
        position: relative;
    }

    img {
        align-self: flex-end;
        height: 130px;
        pointer-events: none;
    }

    p {
        margin: 0;
        padding: 25px 25px 25px 0;

        span {
            padding-top: 4px;
            display: block;
            font-weight: 500;
        }
    }
}

.card-skybar {
    width: 100%;
    padding-right: 60px;
    max-width: 660px;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        width: 110px;
        height: 110px;
        background-color: var(--blue);
        z-index: 0;
        border-radius: 110px;
        position: absolute;
        right: 0px;
        top: -30px;
    }

    .card-innerbar {
        display: flex;
        align-items: center;
        background-color: var(--sky);
        border-radius: 16px;
        overflow: hidden;
        position: relative;
    }

    img {
        align-self: flex-end;
        height: 130px;
        margin-right: 20px;
        pointer-events: none;
    }

    p {
        margin: 0;
        padding: 25px 0px 25px 25px;

        span {
            padding-top: 4px;
            display: block;
            font-weight: 500;
        }
    }

    &.margin-img-top-res::before {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .home-section5 {
        padding-top: 80px;
        padding-bottom: 140px;

        .homebg-cover {
            position: absolute;
            width: 300%;
            height: calc(100% - 20px);
            top: 10px;
            left: -100%;
        }
    }
}

@media screen and (max-width: 576px) {
    .card-limebar {
        padding-left: 0px;

        &::before {
            width: 84px;
            height: 84px;
            left: -15px;
            bottom: -40px;
        }

        img {
            margin-left: -85px;
        }

        p {
            padding: 15px 15px 15px 15px;
        }
    }

    .card-skybar {
        padding-right: 0px;

        &::before {
            width: 84px;
            height: 84px;
            right: -9px;
            top: -40px;
        }

        img {
            margin-right: -45px;
        }

        p {
            padding: 15px 15px 15px 15px;
        }
    }
}

.home-section7 {
    position: relative;
    overflow: hidden;
    padding-top: 120px;
    padding-bottom: 120px;
    margin-top: 50px;
    margin-bottom: 80px;

    .homebg-cover {
        position: absolute;
        width: 120%;
        height: calc(100% - 20px);
        top: 10px;
        left: -10%;
        z-index: 0;
        user-select: none;
        object-position: center;
    }
}
.home-section7.section7-manage-padding{
    margin-top: 20px;
    margin-bottom: 40px;
}
@media (max-width: 576px) {
    .home-section7 {
        padding-top: 100px;
        padding-bottom: 100px;
        margin-bottom: 40px;

        .homebg-cover {
            width: 300%;
            left: -120%;
        }
    }
}

.shape-section8 {
    position: relative;
    overflow: hidden;
    padding-top: 120px;
    padding-bottom: 120px;
    margin-top: 10px;
    margin-bottom: 50px;

    .homebg-cover {
        position: absolute;
        width: 120%;
        height: calc(100% - 20px);
        top: 10px;
        left: -10%;
        z-index: 0;
        user-select: none;
        object-position: center;
    }
}

.line-heading {
    font-size: 18px;
    font-weight: 700;
    position: relative;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    overflow: hidden;

    &::before {
        content: "";
        min-width: 24px;
        width: 24px;
        height: 1px;
        background: currentColor;
        margin-right: 12px;
    }

    span {
        display: inline-flex;
        align-items: center;
        white-space: nowrap;

        &::after {
            content: "";
            display: block;
            width: 100vw;
            height: 1px;
            margin-left: 12px;
            background: currentColor;
        }
    }
}

/*Start | Left Aside Bar Style*/
.mobile-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 56px;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    visibility: hidden;
    z-index: -10;

    &::after {
        content: "";
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        visibility: hidden;
        transition: 300ms;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
    }

    img {
        height: 44px;
    }
}

.left-aside {
    position: fixed;
    top: 0;
    left: 0;
    width: 290px;
    height: 100%;
    background: #eeeeee;
    display: flex;
    flex-direction: column;
    z-index: 12;
    transition: 300ms;

    .left-logobox {
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        padding: 20px;

        img {
            height: 50px;
        }

        button {
            visibility: hidden;
        }
    }

    .left-menulist {
        list-style-type: none;
        padding: 20px 20px 20px 20px;
        margin: 0;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;

        .sub-link {
            display: flex;
            align-items: center;
            position: relative;
            text-decoration: none;
            padding-left: 49px;
            min-height: 32px;
            color: var(--blue-grey);
            font-size: 16px;
            transition: 350ms;
            border-radius: 4px;
            white-space: nowrap;

            &.active,
            &:hover {
                color: var(--blue);
                background-color: #f9fcff;
            }
        }

        .left-notifybadge {
            background-color: var(--red);
            color: #fff;
            font-weight: normal;
            font-size: 11px;
            line-height: 1;
            letter-spacing: 0.5px;
            padding: 0 2px;
            min-width: 18px;
            min-height: 18px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 2px;
            margin-left: 12px;
        }

        .left-menulabel {
            font-size: 16px;
            padding-left: 30px;
            color: var(--blue-grey);
            margin-top: 30px;
            margin-bottom: 6px;
            font-weight: bold;
            white-space: nowrap;
            transition: 350ms;
            border-top: 1px solid transparent;
        }
    }

    .left-menubottom {
        border-top: 1px solid var(--blue-grey);
        height: 48px;
        min-height: 48px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        text-decoration: none;
    }
}

.main-link {
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: none;
    padding-left: 15px;
    min-height: 40px;
    max-height: 40px;
    color: var(--blue-grey);
    transition: 350ms;
    border-radius: 4px;

    &.open {
        color: var(--blue);
    }

    &.active {
        color: var(--blue);
        background-color: #f9fcff;

        &::before {
            content: "";
            position: absolute;
            width: 4px;
            height: 100%;
            background-color: var(--sea-green);
            left: 0;
            top: 0;
        }
    }

    &:hover {
        color: var(--blue);
        background-color: #f9fcff;
    }

    span {
        font-weight: 700;
        font-size: 18px;
        margin-left: 12px;
        display: inline-block;
        white-space: nowrap;
    }

    .icon {
        min-width: 22px;
        width: 22px;
        height: 22px;
        font-size: 16px;
    }
}

.more-menu {
    position: relative;

    .main-link {
        padding-right: 40px;
    }

    button {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        width: 34px;
        height: 100%;
        padding: 0;
        border: none;
        outline: none;
        font-size: 22px;
        background: transparent;
        border-radius: 4px;

        &:hover {
            color: var(--blue);
            background-color: #f9fcff;
        }

        &:not(.collapsed) {
            color: var(--blue);
            background-color: #f9fcff;

            .icon {
                transform: rotate(-180deg);
            }
        }
    }
}

/*End | Left Aside Bar Style*/
.wrapper-box {
    width: 100%;
    min-height: 100vh;
    padding-top: 20px;
    padding-left: 300px;
    padding-right: 10px;
    padding-bottom: 20px;
    box-sizing: border-box;
    transition: 300ms;
}

footer {
    background-color: var(--green);
    padding-top: 46px;
    padding-bottom: 15px;

    .footer-social {
        a {
            display: inline-flex;
            font-size: 24px;
            width: 48px;
            height: 48px;
            border-radius: 48px;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: #fff;
            border: 1px solid currentColor;
            margin-right: 24px;
            transition: 300ms;

            &:hover {
                color: var(--green);
                background-color: #ffffff;
            }
        }
    }

    .footer-slogan {
        margin-top: 24px;
        margin-bottom: 0px;
        color: #fff;
        font-weight: 700;
    }

    .footer-link {
        display: inline-flex;
        font-weight: 500;
        color: #fff;
        text-decoration: none;
        line-height: 21px;
        margin-top: 20px;
        transition: 300ms;
        border-bottom: 1px solid transparent;

        a {
            color: #fff;
            text-decoration: none;
        }

        &:hover {
            border-bottom: 1px solid currentColor;
        }
    }

    .footer-link-copyright {
        display: block;
        font-weight: 500;
        color: #fff;
        text-decoration: none;
        line-height: 21px;
        // margin-top: 10px;
        transition: 300ms;
        border-bottom: 1px solid transparent;

        a {
            color: #fff;
            text-decoration: underline;
        }

    }

}

.question-controls {
    color: #333;
    display: flex;
    align-items: center;

    h3 {
        min-width: 150px;
        margin: 0 24px 0 0;
        white-space: nowrap;
    }

    h4 {
        font-weight: 700;
        font-size: 18px;
        margin: 0;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    button[aria-expanded="true"] {
        color: var(--blue);
        transform: rotate(180deg);
    }
}

.label-cardwrap {
    display: block;
    position: relative;
    height: 100%;

    input {
        opacity: 0;
        left: 0;
        top: 0;
        position: absolute;

        &:checked~.card {
            border-color: var(--blue);
            box-shadow: 0 0 0 1px var(--blue);
            background: #f9fcff;

            .clone-checkbox {
                background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
                background-color: var(--blue);
                border-color: var(--blue);
            }
        }
    }

    .card {
        height: 100%;
        cursor: pointer;

        &:hover {
            border-color: var(--sky);
            box-shadow: 0 0 0 1px var(--sky);
            background: #f9fcff;
        }

        .clone-checkbox {
            border: 1px solid var(--blue);
        }
    }
}

.dropzone-wrapper {
    position: relative;
    border: 2px dashed var(--dim);
    border-radius: 8px;
    padding: 30px;
    display: flex;
    align-items: center;

    &:hover {
        border-color: var(--blue);
        cursor: pointer;
    }

    input {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        opacity: 0;
    }

    h4 {
        margin: 0;
        padding-left: 20px;
        font-weight: 700;
    }
}

.signup-unlock {
    position: relative;
    width: 100%;
    z-index: 0;

    .signup-inner {
        margin: 10px auto;
        background: #fefefe;
        max-width: 350px;
        padding: 30px;
        border: 2px solid var(--green);
        border-radius: 16px;
        position: relative;
        z-index: 1;
    }

    .signup-pill {
        width: 220px;
        height: 70px;
        position: absolute;
        color: var(--green);
        top: -25px;
        left: -20px;
        z-index: -1;
    }

    .signup-circle {
        width: 150px;
        height: 150px;
        position: absolute;
        color: var(--lime);
        bottom: -50px;
        right: -20px;
        z-index: -1;
    }
}

.table-common {
    &> :not(caption)>*>* {
        padding: 0.85rem 0.5rem;
    }

    thead {
        span {
            font-weight: 700;
            text-transform: uppercase;
            font-size: 14px;
            cursor: pointer;
            white-space: nowrap;

            &.sort-asc,
            &.sort-des {
                color: var(--blue);

                &::after {
                    content: "\2193";
                    font-family: monospace;
                    font-weight: 900;
                    margin-left: 5px;
                }
            }

            &.sort-des {
                &::after {
                    content: "\2191";
                }
            }
        }
    }

    tbody {
        tr {
            &.selected {
                background: #f9fcff;
            }

            .show-onhover {
                visibility: hidden;
            }

            &:hover {
                background: rgba(238, 238, 238, 0.5);

                .show-onhover {
                    visibility: visible;
                }
            }
        }
    }
}

.alert {
    border-radius: 8px;
    color: #111111;
    padding: 24px;
}

.alert-warning {
    background: rgba(255, 204, 77, 0.5);
    border: 1px solid #ffcc4d;

    .icon {
        color: var(--bs-warning);
    }
}

.alert-info {
    background: #f9fcff;
    border: 1px solid #6fcafd;

    .icon {
        color: var(--bs-info);
    }
}

.alert-green {
    background: #fafff9;
    border: 1px solid #12d76c;

    .icon {
        color: var(--green);
    }
}

.alert-danger {
    background: #fffdfd;
    border: 1px solid var(--bs-danger);

    .icon {
        color: var(--bs-danger);
    }
}

.toast-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1180px;
    pointer-events: none;
    padding: 10px 10px 5px 10px;

    .toast {
        width: 100%;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        border-radius: 8px;
        background: #fff;

        .icon {
            min-width: 24px;
            width: 24px;
            height: 24px;
        }

        &.toast-success {
            background: #fafff9;
            border-color: var(--green);

            .icon {
                color: var(--green);
            }
        }

        &.toast-danger {
            background: #fffdfd;
            border-color: var(--bs-danger);

            .icon {
                color: var(--bs-danger);
            }
        }

        &.toast-warning {
            background: #faf5e8;
            border: 1px solid #ffcc4d;

            .icon {
                color: var(--bs-warning);
            }
        }

        &.toast-info {
            background: #f9fcff;
            border: 1px solid #6fcafd;

            .icon {
                color: var(--bs-info);
            }
        }

        .toast-body {
            color: #111111;
            font-size: 16px;
            padding: 24px;
            font-weight: 700;
            display: flex;
        }
    }
}

@media screen and (max-width: 991px) {
    .w-sm100 {
        width: 100% !important;
    }
}

@media screen and (max-width: 991px) {
    .aside-filter {
        display: none;
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        top: 0;
        left: 0;
        z-index: 100;
        padding: 50px 15px;
        overflow: hidden;
        overflow-y: auto;

        &.active {
            display: block;
        }

        .aside-innerfilter {
            padding: 25px 15px;
            background-color: #fff;
            border-radius: 20px;
            margin-top: 20%;
        }
    }

    .triangle {
        left: 57%;
        top: 92%;
    }

    .triangle.border.border-blue.rounded.border-2.mmwh-44.position-absolute {
        left: unset;
        top: unset;
    }
}

.filter-checkwrap {
    padding: 10px 14px;
    border-radius: 8px;
    transition: 200ms;

    &.active {
        background-color: #eeeeee;
    }

    .filter-checkarrow {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        button {
            min-width: 32px;
            width: 32px;
            height: 32px;
            max-height: 32px;
            border: none;
            font-size: 18px;
            background-color: transparent;
            padding: 0;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            border-radius: 4px;

            &[aria-expanded="false"] {
                color: var(--blue);

                .icon {
                    transform: rotate(-180deg);
                }
            }

            &:hover {
                box-shadow: inset 0 0 2px var(--bs-body-color);
            }
        }
    }

    .collapse,
    .collapsing {
        padding-left: 38px;
    }
}

/*Start | Predefined Classes*/
.rating-box {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    line-height: 1;

    .icon {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    .rating-gray {
        .icon {
            fill-opacity: 0;
            stroke-width: 1;
        }
    }

    .rating-yellow {
        position: absolute;
        overflow: hidden;
        width: 0;
        top: 0;
        left: 0;
        z-index: 2;

        .icon {
            fill: #ffcc4d;
            stroke-width: 1;
            stroke-opacity: 0;
        }
    }
}

.nav-bar {
    border: none;
    --bs-nav-link-padding-y: 0.22rem;

    .nav-link {
        padding-left: 0;
        padding-right: 0;
        font-size: 24px;
        font-weight: 700;
        border: none;
        border-bottom: 3px solid transparent;
        color: rgba(17, 17, 17, 0.3);
        margin-right: 22px;
        border-radius: 0;

        &.active {
            border-bottom: 3px solid var(--blue);
        }
    }
}

.form-control {
    &[type="search"] {
        padding-left: 38px;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%236c757d' viewBox='0 0 512 512'%3E%3Cpath d='M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-position: 12px center;
    }

    &.maxw-25p {
        width: auto !important;
    }
}

.form-control-md {
    min-height: calc(1.5em + 1rem + 4px);
    padding: 0.25rem 1rem;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 0.5rem;

    &::placeholder {
        color: var(--dim);
    }
}

.form-select-md {
    padding-top: 0.563rem;
    padding-bottom: 0.563rem;
    padding-left: 1rem;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 0.5rem;
}

.form-floating {

    >.form-control-plaintext~label,
    >.form-control-md:focus~label,
    >.form-control:not(:placeholder-shown)~label,
    >.form-select~label {
        opacity: 1;
        font-size: 12px;
        transform: scale(1) translateY(-1.9rem) translateX(0);
    }

    >.form-control-md,
    >.form-select-md {
        height: calc(1.5em + 1rem + 4px);
        padding: 0.25rem 2rem 0.25rem 1rem !important;
        font-size: 1rem;
        font-weight: 700;
        border-radius: 0.5rem;
    }

    >textarea.form-control-md {
        padding: 0.6rem 1rem !important;
    }

    >label {
        padding: 0.6rem 1rem;
        font-size: 1rem;
        font-weight: 700;
        color: #8a8a8a;
    }
}

.form-dropdown {
    .form-select[aria-expanded="true"] {
        background-image: url("data:image/svg+xml,<svg fill='%23F4900C' viewBox='0 0 10 11' xmlns='http://www.w3.org/2000/svg'><path d='M10 5.5L9.11875 4.61875L5.625 8.10625V0.5H4.375V8.10625L0.8875 4.6125L0 5.5L5 10.5L10 5.5Z'/></svg>");
        -webkit-appearance: none !important;
        appearance: none !important;
    }

    .form-control,
    .dropdown-item {
        display: flex;
        align-items: center;
        padding-right: 36px;

        img {
            width: 34px;
            height: 34px;
            object-fit: cover;
            object-position: center;
            border-radius: 6px;
            border: 1px solid #ddd;
            margin-right: 10px;
            background-color: #fff;
            pointer-events: none;
        }

        span {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            width: 90%;
            text-overflow: ellipsis;
            position: relative;
            pointer-events: none;
        }
    }

    .dropdown-item {
        cursor: pointer;
    }
}

@function shade-color($color, $percentage) {
    @return mix(black, $color, $percentage);
}

.btn-theme-primary {
    --bs-btn-font-weight: 600;
    --bs-btn-color: var(--bs-white);
    --bs-btn-bg: var(--blue);
    --bs-btn-border-color: var(--blue);
    --bs-btn-border-radius: 0.5rem;
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-hover-bg: #{shade-color($blue, 10%)};
    --bs-btn-hover-border-color: #{shade-color($blue, 10%)};
    --bs-btn-focus-shadow-rgb: var(--blue-rgb);
    --bs-btn-active-color: var(--bs-btn-hover-color);
    --bs-btn-active-bg: #{shade-color($blue, 20%)};
    --bs-btn-active-border-color: #{shade-color($blue, 20%)};
    position: relative;
    transition: 300ms;

    &:disabled,
    &.disabled {
        background-color: var(--blue);
        border-color: var(--blue);
        color: #fff;
        box-shadow: none;
        opacity: 0.4;

        &::after {
            opacity: 0;
        }
    }

    &:focus {
        box-shadow: none;
    }

    &.active,
    &:active {
        transform: translateY(4px);
        box-shadow: none !important;

        &::after {
            opacity: 0;
        }
    }

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: -5px;
        border-radius: 0.5rem;
        z-index: 0;
        border-bottom: 4px solid #99cdff;
        transition: 300ms;
    }
}
.btn-theme-success {
    --bs-btn-font-weight: 600;
    --bs-btn-color: var(--bs-white);
    --bs-btn-bg: var(--green);
    --bs-btn-border-color: var(--green);
    --bs-btn-border-radius: 0.5rem;
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-hover-bg: #{shade-color($green, 10%)};
    --bs-btn-hover-border-color: #{shade-color($green, 10%)};
    --bs-btn-focus-shadow-rgb: var(--green-rgb);
    --bs-btn-active-color: var(--bs-btn-hover-color);
    --bs-btn-active-bg: #{shade-color($green, 20%)};
    --bs-btn-active-border-color: #{shade-color($green, 20%)};
    position: relative;
    transition: 300ms;

    &:disabled,
    &.disabled {
        background-color: var(--green);
        border-color: var(--green);
        color: #fff;
        box-shadow: none;
        opacity: 0.4;

        &::after {
            opacity: 0;
        }
    }

    &:focus {
        box-shadow: none;
    }

    &.active,
    &:active {
        transform: translateY(4px);
        box-shadow: none !important;

        &::after {
            opacity: 0;
        }
    }

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: -5px;
        border-radius: 0.5rem;
        z-index: 0;
        border-bottom: 4px solid #12d76b70;
        transition: 300ms;
    }
}
.btn-outline-default {
    --bs-btn-font-weight: 600;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: var(--bs-body-color);
    --bs-btn-hover-bg: #{shade-color(#eeeeee, 10%)};
    position: relative;
    transition: 300ms;
    z-index: 2;

    &:disabled,
    &.disabled {
        background: #eeeeee;
        border-color: #cccccc;

        &::after {
            opacity: 0;
        }
    }

    &.active,
    &:active {
        background: #eeeeee;
        transform: translateY(4px);

        &::after {
            opacity: 0;
        }
    }

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: -5px;
        border-radius: 0 0 0.5rem 0.5rem;
        z-index: 0;
        border-bottom: 4px solid #8a8a8a;
        transition: 300ms;
    }
}

.btn-outline-secondary {
    --bs-btn-font-weight: 600;
    --bs-btn-hover-bg: #{shade-color(#eeeeee, 5%)};
    color: #111111;
    position: relative;
    transition: 300ms;
    z-index: 2;

    &:disabled,
    &.disabled {
        background: #eeeeee;

        &::after {
            opacity: 0;
        }
    }

    &:hover,
    &:focus {
        box-shadow: none;
        color: #111111;
    }

    &.active,
    &:active {
        transform: translateY(4px);
        box-shadow: none !important;
        color: #ffffff;

        &::after {
            opacity: 0;
        }
    }

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: -5px;
        border-radius: 0 0 .5rem .5rem;
        z-index: 0;
        border-bottom: 4px solid var(--bs-secondary);
        transition: 300ms;
    }

    &.half-drag {
        border-radius: 0 8px 8px 0 !important;
    }
}

.btn-green {
    --bs-btn-font-weight: 600;
    --bs-btn-color: var(--bs-white);
    --bs-btn-bg: var(--green);
    --bs-btn-border-color: var(--green);
    --bs-btn-border-radius: 0.5rem;
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-hover-bg: #{shade-color($green, 10%)};
    --bs-btn-hover-border-color: #{shade-color($green, 10%)};
    --bs-btn-focus-shadow-rgb: var(--green-rgb);
    --bs-btn-active-color: var(--bs-btn-hover-color);
    --bs-btn-active-bg: #{shade-color($green, 20%)};
    --bs-btn-active-border-color: #{shade-color($green, 20%)};
    position: relative;
    transition: 300ms;

    &:disabled,
    &.disabled {
        background-color: var(--green);
        border-color: var(--green);
        color: #fff;
        box-shadow: none;
        opacity: 0.4;

        &::after {
            opacity: 0;
        }
    }

    &:focus {
        box-shadow: none;
    }

    &.active,
    &:active {
        transform: translateY(4px);
        box-shadow: none !important;

        &::after {
            opacity: 0;
        }
    }

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: -5px;
        border-radius: 0.5rem;
        z-index: 0;
        border-bottom: 4px solid #bdffc8;
        transition: 300ms;
    }
}

.btn-danger {
    --bs-btn-font-weight: 600;
    --bs-btn-color: var(--bs-white);
    --bs-btn-bg: var(--bs-danger);
    --bs-btn-border-color: var(--bs-danger);
    --bs-btn-border-radius: 0.5rem;
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-hover-bg: #{shade-color($danger, 10%)};
    --bs-btn-hover-border-color: #{shade-color($danger, 10%)};
    --bs-btn-focus-shadow-rgb: var(--bs-danger-rgb);
    --bs-btn-active-color: var(--bs-btn-hover-color);
    --bs-btn-active-bg: #{shade-color($danger, 20%)};
    --bs-btn-active-border-color: #{shade-color($danger, 20%)};
    position: relative;
    transition: 300ms;

    &:disabled,
    &.disabled {
        background-color: var(--bs-danger);
        border-color: var(--bs-danger);
        color: #fff;
        box-shadow: none;
        opacity: 0.4;

        &::after {
            opacity: 0;
        }
    }

    &:focus {
        box-shadow: none;
    }

    &.active,
    &:active {
        transform: translateY(4px);
        box-shadow: none !important;

        &::after {
            opacity: 0;
        }
    }

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: -5px;
        border-radius: 0.5rem;
        z-index: 0;
        border-bottom: 4px solid #ff9999;
        transition: 300ms;
    }
}

.btn-yellow {
    --bs-btn-font-weight: 600;
    --bs-btn-color: var(--bs-dark);
    --bs-btn-bg: var(--yellow);
    --bs-btn-border-color: var(--yellow);
    --bs-btn-border-radius: 0.5rem;
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-hover-bg: #{shade-color($yellow, 10%)};
    --bs-btn-hover-border-color: #{shade-color($yellow, 10%)};
    --bs-btn-focus-shadow-rgb: var(--bs-danger-rgb);
    --bs-btn-active-color: var(--bs-btn-hover-color);
    --bs-btn-active-bg: #{shade-color($yellow, 20%)};
    --bs-btn-active-border-color: #{shade-color($yellow, 20%)};
    position: relative;
    transition: 300ms;

    &:disabled,
    &.disabled {
        background-color: var(--yellow);
        border-color: var(--yellow);
        color: #000;
        box-shadow: none;
        opacity: 0.6;

        &::after {
            opacity: 0;
        }
    }

    &:focus {
        box-shadow: none;
    }

    &.active,
    &:active {
        transform: translateY(4px);
        box-shadow: none !important;

        &::after {
            opacity: 0;
        }
    }

    &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: -5px;
        border-radius: 0.5rem;
        z-index: 0;
        border-bottom: 4px solid #ffdc99;
        transition: 300ms;
    }
}

.btn-link {
    --bs-btn-color: var(--blue);
}

.btn {
    &:disabled {
        &::after {
            opacity: 0;
        }
    }
}

/*End | Predefined Classes*/

@mixin track() {
    border: none;
    width: 100%;
    height: $track-h;
    border-radius: $track-h;
    background: linear-gradient(to right,
            #ff0000 0%,
            #ffff00 17%,
            #00ff00 33%,
            #00ffff 50%,
            #0000ff 67%,
            #ff00ff 83%,
            #ff0000 100%);
}

@mixin thumb() {
    border: 4px solid white;
    width: $thumb-d;
    height: $thumb-d;
    border-radius: 50%;
    background: var(--color-alpha);
    box-shadow: var(--box-shadow);
}

.color-range {
    position: relative;
    width: 100%;
    padding: 20px 1px;
    background-color: #fff;

    .color-range-holder {
        display: flex;
        align-items: center;
        position: relative;
    }
}

.color-range-slider {
    width: 100%;
    margin: 0;
    padding: 0;
    min-height: $thumb-d;
    background: transparent;
    outline: none;

    &,
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
    }

    &::-webkit-slider-runnable-track {
        @include track();
    }

    &::-moz-range-track {
        @include track;
    }

    &::-ms-track {
        @include track;
    }

    &::-webkit-slider-thumb {
        margin-top: 0.5 * ($track-h - $thumb-d);
        @include thumb;
    }

    &::-moz-range-thumb {
        @include thumb;
    }

    &::-ms-thumb {
        margin-top: 0;
        @include thumb;
    }

    &::-ms-tooltip {
        display: none;
    }

    &:focus,
    &:active {
        +.color-range-output {
            opacity: 1; //transform: translate(calc((var(--hue) / 360) * -50%), 0);
        }
    }
}

.color-range-output {
    opacity: 0;
    position: absolute;
    left: calc((var(--hue) / 360 * 100%) - #{$thumb-d / 2});
    transform: translate(calc((var(--hue) / 360) * -50%), 0);
    width: $thumb-d * 2;
    top: -36px;
    padding: 4px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    border-radius: 12px;
    background-color: var(--color-dark);
    color: white;
    transition-property: opacity;
    transition-duration: var(--transition-duration);
    transition-timing-function: var(--transition-easing);

    // little triangle
    &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: -4px;
        left: 50%;
        margin-left: -4px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 4px 0 4px;
        border-color: var(--color-dark) transparent transparent transparent;
    }
}

.card-tab {
    cursor: pointer;

    &:hover {
        background-color: rgba($color: $blue, $alpha: 0.025);
    }

    &[aria-expanded="true"] {
        border-color: var(--blue) !important;
        box-shadow: 0 0 2px var(--blue);
        pointer-events: none;
        background-color: rgba($color: $blue, $alpha: 0.015);
    }
}

form#billerDetailsForm select option[value=""] {
    color: #ffffff;
    background-color: #525252;
}

/*Start | Responsive Style*/
@media (max-width: 576px) {
    .sm-w-100 {
        width: 100% !important;
    }

    .sm-mmwh-28 {
        min-width: 28px;
        max-width: 28px;
        min-height: 28px;
        max-height: 28px;
    }

    .smt-mmwh-100 {
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
    }

    .sm-ps-46 {
        padding-left: 46px;
    }

    .sm-ps-48 {
        padding-left: 48px;
    }

    .sm-ps-50 {
        padding-left: 50px;
    }
}

.slick-visible-arrow {
    &.active {
        .slick-common-left {
            visibility: visible;
        }
    }

    .slick-common-left {
        visibility: hidden;
    }
}

.slick-common-left,
.slick-common-right {
    position: absolute;
    left: calc(-24px);
    top: calc(50% - 24px);
    width: 48px;
    height: 48px;
    font-size: 24px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--blue);
    border: 1px solid var(--blue);
    border-radius: 50%;
    z-index: 10;
    cursor: pointer;
    opacity: 0.7;
    transition: 300ms;

    &:hover {
        opacity: 1;
    }
}

div#slideABC .slick-common-left {
    left: -8px;
}

.slick-common-right {
    left: auto;
    // right: calc(-24px);
    right: calc(-8px);
}

.slick-box {
    aspect-ratio: 1 / 1;
    background-color: #eee;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    .slick-imgbox {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.slick-hovershow {
    &:hover {
        .slick-hide {
            opacity: 1;
            transform: scale(0.93);
            border-radius: 8px;
        }
    }

    .slick-hide {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.75);
        padding: 15px;
        z-index: 2;
        opacity: 1;
        transition: 300ms;
        transform: scale(1);
    }

    .slick-hide {
        // animation: tree 1s linear;
    }

    @keyframes tree {
        0% {
            opacity: 0.4;
            transform: scale(1);
            // transform: rotate(5deg);
            border-radius: 20px
        }

        100% {
            opacity: 1;
        }
    }
}

.card-shadow {
    box-shadow: 0 4px #9e9e9e;
    border-radius: 8px;
    background: #fefefe;
    border: 1px solid rgba(138, 138, 138, 1);
    transition: 300ms;
    text-decoration: none;

    &.active,
    &:hover {
        background: rgba(238, 238, 238, 1);
    }
}

@media (min-width: 768px) {
    .mmt-md-80 {
        margin-top: -80px;
    }

    .modal-md {
        --bs-modal-width: 600px;
    }
}

/* New Changes */
.image-show {
    position: relative;
}

.image-show .image-box {
    position: relative;
}

.image-show button {
    position: absolute;
    top: 0;
}

.btn-1 {
    margin-right: 195px;
}

select {
    &:invalid {
        color: #999;
    }

    option[value=""] {
        color: #999999;
    }

    :not(option[value=""]) {
        color: #000;
    }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
    .grid-css_desktop .col-xl-4.grid-desk {
        flex: 0 0 auto;
        width: 35%;
    }

    .custom_width_responsive .minw-70 {
        min-width: 57px;
    }
}

@media (max-width: 1024.1px) {
    .mobile-header {
        visibility: visible;
        z-index: 10;
    }

    .left-aside {
        left: -320px;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);

        .left-logobox {
            img {
                height: 50px;
            }

            button {
                visibility: visible;
            }
        }
    }

    .wrapper-box {
        padding-top: 76px;
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 20px;
    }

    body.menu-toggle {
        .mobile-header {
            &::after {
                visibility: visible;
                opacity: 1;
            }
        }

        .left-aside {
            left: 0;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.image-container {
    position: relative;
    margin: 10px !important;
    width: 100%;
    height: 150px;
}

.image-container .custom-thumb {
    padding: 0;
    border: 2px solid #00000042;
    overflow: hidden;
    object-fit: cover;
    box-shadow: 0 5px 20px rgb(0 0 0 / 7%);
    position: absolute;
    left: 0;
    height: 150px;
    width: 100%;
}

.image-container .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    transition: background 0.5s ease;
}

.image-container:hover .overlay {
    display: block;
    background: rgba(0, 0, 0, 0.3);
}

.image-container .title {
    position: absolute;
    width: 100%;
    left: 2px;
    top: -25px;
    font-weight: normal;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
    z-index: 1;
    transition: top 0.5s ease;
}

.image-container .title {
    margin: 0 auto;
    color: white;
    background-color: rgba(0, 0, 0, 0.9) !important;
    width: fit-content;
    padding: 2px;
    border-radius: 2px;
}

.image-container:hover .title {
    top: 4px;
}

.image-container .button {
    position: absolute;
    width: 100%;
    left: 0;
    top: 48%;
    text-align: center;
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.35s ease;
    text-decoration: none;
}

.image-container .button a {
    padding: 10px 12px;
    text-align: center;
    color: white;
    border: solid 2px white;
    border-radius: 30px;
    z-index: 1;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.16) !important;
}

.image-container .button a:hover {
    background-color: rgba(0, 0, 0, 0.9) !important;
}

.image-container .del-button {
    position: absolute;
    right: 2px;
    top: 0px;
    opacity: 0;
    cursor: pointer;
    transition: opacity 0.35s ease;
    text-decoration: none;
    padding: 0px;
    margin: 0px;
}

.image-container .del-button a {
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    z-index: 1;
}

.image-container .del-button img {
    padding: 0px;
    margin: 0px;
    width: 36px;
    transition: opacity 0.35s ease;
}

.image-container .del-button a:hover img {
    width: 40px;
}

.image-container:hover .button,
.image-container:hover .del-button {
    opacity: 1;
}

/*Workbook Question*/

%is_yes_no {
    content: "";
    position: absolute;
    top: 50%;
    margin-left: 14px;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
}

.question {
    scrollbar-color: #c4c4c4 transparent;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: 4px;
        height: 3px;
        background: transparent;
    }

    &::-webkit-scrollbar-track {
        border-radius: 1px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: rgba(196, 196, 196, 1);
        transition: 0.3s;
    }

    p {
        position: relative;
        z-index: 0;
        margin-bottom: 0;
        // font-size: 18px;
        // font-weight: bold;

        &.active {
            font-size: 16px;
            font-weight: 700;
        }

        &.is--yes {
            color: #12d76c;

            &::after {
                @extend %is_yes_no;
                background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAALCAYAAABLcGxfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADgSURBVHgBjVFLCsIwEJ1JiuuCF3BbcKE30JPY7sQPeAPpDQRR3Nkb6A16BBULbnuCkqWgzZj0r5TWgUB4817emwnCH2U+5hOGuJJv6WA7edpjaFyAwJQgHdYmYMDXmkxAITw750ZBN1jaAGjruxK4YrgR2BgFuK8EPXW8yNo6qaNuBrNBbRRF1lEkvNwcR21LnI6QNOKxsA5hiQHoQYW194qHYq6GSbVJBPO+GBGX6wzzquQEySMxg/l6G3lDR6HM8TuqKtHfX9WnjJPVlQL3l1w4VDeDaJyQ6BZZOxtq6gMsw2N6ykOXBAAAAABJRU5ErkJggg==");
            }
        }

        &.is--no {
            color: #dd2e44;

            &::after {
                @extend %is_yes_no;
                background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAANCAYAAACdKY9CAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACtSURBVHgBnZHLDcIwDIZjb8Lr2M4AqIPAJCSTwCARMEN7pIhNEuxUjkJwD/Bf4tif5ZcxPwrGZn8AwDMaYxe9dxr0bLsTPTbGcERxBnK8poAKyx/Xw/VCsNOSKtgxCxJ8tJ2lcgnm9sLkzvCq98mGsnyZVCjDXwmsseluAGabgtHcl4PflXGsBxSYFcmuF4FzA84tAjRYeq4XwXfKh6thbREfh9Ng1oZ80t5fegNEzVZpc0H4TAAAAABJRU5ErkJggg==");
            }
        }

        a {
            color: inherit;
            text-decoration: none;
            font-size: 16px;
        }
    }

    &.default_border_es {
        border: 3px solid var(--blue) !important;
    }

    &.correct_border_es {
        border: 3px solid var(--green) !important;
    }

    &.incorrect_border_es {
        border: 3px solid #dc3545 !important;
    }

    .default_text_es {
        color: var(--blue) !important;
    }

    .correct_text_es {
        color: var(--green) !important;
    }

    .incorrect_text_es {
        color: #dc3545 !important;
    }
}

.raw-question {
    p {
        padding: 5px 0px 0px 0px;
    }
}

.offcanvas {
    height: max-content;
}

.exammode {
    &>img {
        position: absolute;
        top: -16px;
    }

    .questionlink {
        text-decoration: none;
        display: inline-block;
        vertical-align: middle;
        color: inherit;
    }

    .exam-title {
        position: absolute;
        top: -20px;
        left: 20px;
        background: #ffffff;
        padding: 0 5px;
    }
}

.check-box::before {
    content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHISURBVHgBtZU7SMNAHMa/OxQfg9bHIlUR1EHoIC4OLl2ddBXUyUGkvhYdHMTRTkpFENxEdBKddLOLg5tgQUER6UNBrLYdakshZ/7BhDzapjbpb0kud/wf3313YZDx3Af8nMMPF5GEdJ7y7d8xz2NgmQvsoBYIscq5ECuoFZxtcoD1oVYIeDhcoKe+HWsd4/A1ei1zrP0hIOAACn7Rs4Re+Un0P60jLf1o8447oMrV4IS5C0cJxpoGMNU6qo1P0re4yT67k6CFNyHUNa2N01IWweSlZV3VCebb/AZpgp9XiBW+3EmguKZzXBtHcnEcfIeLrq0qAblGz8zbYcm1vFSFpTC7hnQvJo1KHSzVLWKseVBpeyIWMnjaLE20kJS1v0Q5DB34GrxKcOW9sRvXfeuGbszSUAF2GBJQq1SVSu/fKVWvgv9Io2K5KijYkXdO7qZb+xaVA+mDUxEjL1uoBMsmU1UT0RAi+Tj0neiZLeMa2wQEbaz/NYjTzK1ljvweySXgKIFK4P3YcIBImm0b15ips1uw8XGG+3xCudhoYzM621aC4/+BHRwMKdQIAfHKJUlU5rcqYAK7jF48kYVhzvgkXETiCKeG9sK/oLyliYM65Q8AAAAASUVORK5CYII=");
    position: absolute;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHISURBVHgBtZU7SMNAHMa/OxQfg9bHIlUR1EHoIC4OLl2ddBXUyUGkvhYdHMTRTkpFENxEdBKddLOLg5tgQUER6UNBrLYdakshZ/7BhDzapjbpb0kud/wf3313YZDx3Af8nMMPF5GEdJ7y7d8xz2NgmQvsoBYIscq5ECuoFZxtcoD1oVYIeDhcoKe+HWsd4/A1ei1zrP0hIOAACn7Rs4Re+Un0P60jLf1o8447oMrV4IS5C0cJxpoGMNU6qo1P0re4yT67k6CFNyHUNa2N01IWweSlZV3VCebb/AZpgp9XiBW+3EmguKZzXBtHcnEcfIeLrq0qAblGz8zbYcm1vFSFpTC7hnQvJo1KHSzVLWKseVBpeyIWMnjaLE20kJS1v0Q5DB34GrxKcOW9sRvXfeuGbszSUAF2GBJQq1SVSu/fKVWvgv9Io2K5KijYkXdO7qZb+xaVA+mDUxEjL1uoBMsmU1UT0RAi+Tj0neiZLeMa2wQEbaz/NYjTzK1ljvweySXgKIFK4P3YcIBImm0b15ips1uw8XGG+3xCudhoYzM621aC4/+BHRwMKdQIAfHKJUlU5rcqYAK7jF48kYVhzvgkXETiCKeG9sK/oLyliYM65Q8AAAAASUVORK5CYII=");
    background: no-repeat;
    text-align: center;
    margin: auto;
    display: contents;
}

.cross-box::before {
    content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGlSURBVHgBtZU/S8NAGMbfxFCktlI1paAI/kFdpHRw0iWFxrHoLO46FYeuUl07qFM/g+AgBZfWoR/AQUSEIjVOoohQbAYpMfHeQCR/L1dIftPxXp7nOe7uvXBAeF4vScCDBBFiaNz16lP7nlPyckUH4xzi4Yjr5WWF5C1APPT5GM2RDO+uTB3sw9xlA5LFTWAlXZZNzUz10DPnCEDTDAlIrC1D7qxmClnMxdOqqZnc24XxjXxwgD5QHZMopIVY5na0t4/ggJ+7B/g8rjOF+Jmj1h0wVskt1eyFYfeFfPQOE8Wt/1qSjH9JDedo5mqz7VmIAD5YH2ZtJmLAmGaOkD4oGRBAiqw06zJzQzNHeJoYhe4zGcU8NMAKUZstn3or1JwpAA80Vd721LHG0id8mLlIOYOwPqEGBF1F1j6xEEYxt++53xUe+JyJp9FYzFma0cKxRcJsjrmJ/K4waqmPHQawmNNC3Di2CB8qPp0CQZyGr3qD6Z7jlgy7PUgszsP31Y1HQ30qogB/mX2IDeMVA04gJnTgLjgcKAWpoOnCDkSJDp2Vx9vOH02ZzdaOfBu9AAAAAElFTkSuQmCC");
    position: absolute;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGlSURBVHgBtZU/S8NAGMbfxFCktlI1paAI/kFdpHRw0iWFxrHoLO46FYeuUl07qFM/g+AgBZfWoR/AQUSEIjVOoohQbAYpMfHeQCR/L1dIftPxXp7nOe7uvXBAeF4vScCDBBFiaNz16lP7nlPyckUH4xzi4Yjr5WWF5C1APPT5GM2RDO+uTB3sw9xlA5LFTWAlXZZNzUz10DPnCEDTDAlIrC1D7qxmClnMxdOqqZnc24XxjXxwgD5QHZMopIVY5na0t4/ggJ+7B/g8rjOF+Jmj1h0wVskt1eyFYfeFfPQOE8Wt/1qSjH9JDedo5mqz7VmIAD5YH2ZtJmLAmGaOkD4oGRBAiqw06zJzQzNHeJoYhe4zGcU8NMAKUZstn3or1JwpAA80Vd721LHG0id8mLlIOYOwPqEGBF1F1j6xEEYxt++53xUe+JyJp9FYzFma0cKxRcJsjrmJ/K4waqmPHQawmNNC3Di2CB8qPp0CQZyGr3qD6Z7jlgy7PUgszsP31Y1HQ30qogB/mX2IDeMVA04gJnTgLjgcKAWpoOnCDkSJDp2Vx9vOH02ZzdaOfBu9AAAAAElFTkSuQmCC");
    background: no-repeat;
    text-align: center;
    margin: auto;
    display: contents;
}

.question-box-exam .popover {
    z-index: 11;
}

/*End | Responsive Style*/

.invalid-feedback {
    font-weight: bold;
    font-size: 0.76rem;
}

.was-validated .password-wrapper button {
    top: 35%;
}

#adminLogin .password-wrapper button {
    top: 50%;
}

form#studentForm .was-validated .password-wrapper button {
    top: 50%;
}

button.arrow::after {
    content: "\2193";
    font-family: monospace;
    font-weight: 900;
    margin-left: 5px;
}

.user-profile {
    position: relative;
    margin: auto;

    & img {
        object-fit: cover;
    }

    &:before {
        content: "\270E";
        position: absolute;
        text-align: center;
        font-size: 55px;
        padding: 40px;
        height: 100%;
        width: 100%;
        color: #fff;
        transform: rotate(95deg);
        background: rgba(0, 0, 0, 0.8);
        cursor: pointer;
        display: none;
    }

    &:hover::before {
        display: block;
    }
}

.overlay-bgbl {
    content: "";
    position: absolute;
    width: auto;
    height: 477px;
    background-color: rgb(243, 243, 243, 0.9);
    top: 0;
    z-index: 99;
    bottom: 15px;
}

.amazing_work img {
    padding-top: 108px;
}

.amazing_work h3 {
    font-size: 28px;
    font-weight: 700;
    color: #f4900c;
}

.conbg-img {
    padding: 5% 21%;
}

div#result-bgclap {
    content: "";
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(243, 243, 243, 0.9);
    z-index: 22;
}

.overlay-bgbl-exam {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(243, 243, 243, 0.9);
    top: 0;
    z-index: 99;
    bottom: 15px;
    border-radius: 16px;
    overflow: hidden;
    object-fit: cover;
}

div#loginModal .was-validated .password-wrapper button {
    top: 50%;
}

/*Responsive*/

@media only screen and (max-width: 767px) {
    section#respon-sec h2 {
        padding-right: 66px;
    }

    .query-shape h4 {
        font-size: 24px;
        line-height: 31px;
    }

    section#respon-sec h2 {
        line-height: 36px;
        font-size: 28px;
    }

    section#respon-sec .query-shape {
        padding: 0;
    }

    section#respon-sec .query-shape .shape2 {
        width: 450px;
        height: unset;
        background-color: #6fcafd00;
        border-radius: 100px;
        position: absolute;
        top: -40px;
        right: 0;
        z-index: 0;
    }

    section#respon-sec .query-shape .shape2::before {
        content: "";
        position: absolute;
        right: -123px;
        z-index: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 84px;
        width: 125px;
        border-radius: 150px 150px 0 0;
        background-color: #1e90ff;
    }

    // section#respon-sec .card-dragging {
    //     border-left: 0;
    //     border-right: 0;
    //     border-radius: 0;
    // }

    section#respon-sec .query-shape .shape1 {
        bottom: -46px;
        left: -171px;
        width: 341px;
        height: 88px;
        background-color: #6fcafd;
        border-radius: 100px;
        position: absolute;
        z-index: 0;
    }

    section#respon-sec .query-shape .shape1::after {
        content: "";
        position: absolute;
        width: unset;
        height: 50%;
        left: unset;
        bottom: 0;
        background-color: #fff0;
        border-radius: 100px;
    }

    section#respon-sec .container.container-1040.position-relative {
        padding: 0;
    }

    section#padding-top {
        padding-top: 35px;
    }

    section#respon-sec svg.icon.mmwh-35.sm-mmwh-28 {
        margin-left: 16px;
    }

    .my-sm-3 {
        margin: 14px 0;
    }

    .mt-check {
        margin-top: 10px;
    }

    footer .footer-link {
        display: unset;
    }
}

@media (max-width: 480px) {
    section#respon-sec .btn-lg {
        padding: 8px;
        font-size: 16px;
    }
}

/*End | Datatable layout modified*/
.pagination-sm .page-item.previous.disabled,
.pagination-sm .page-item.next.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.pagination-sm .page-item.previous .page-link,
.pagination-sm .page-item.next .page-link {
    width: 100px;
    font-weight: 500;
}

.pagination-sm .page-item.active .page-link,
.pagination-sm .page-item.active .page-link:hover {
    background-color: var(--blue);
    border-color: var(--blue);
    color: #fff;
}

.pagination-sm .page-item:hover .page-link {
    background-color: rgba(var(--blue-rgb), 0.15);
    border-color: rgba(var(--blue-rgb), 0.15);
}

.pagination-sm .page-item .page-link {
    height: 40px;
    min-width: 40px;
    text-decoration: none;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    border: 1px solid #dee2e6;
    margin-left: 5px;
    cursor: pointer;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 0.3rem;
    font-size: 16px;
    color: #212529;
}

/*start | User profile style */
.user-box,
.account-details {
    background: #fff;
    border: 2px solid #e5e5e5;
    border-radius: 16px;
    padding: 24px;
    min-height: 280px;
}

.user-box .nav-link {
    text-decoration: none;
    display: block;
    padding: 15px 20px;
    border-radius: 15px;
    font-weight: 600;
    color: #3c3c3c;
    margin-bottom: 5px;
    transition: all ease-in-out 0.3s;
    background-color: transparent;
    border: 0;
    text-align: left;
}

.user-box .nav-link.active,
.user-box .nav-link:hover {
    background-color: #dadada;
}

.head-profilename {
    position: relative;
    margin-bottom: 20px;
}

.head-profilename img {
    max-width: 50px;
    position: absolute;
    left: 0;
    top: 0;
}

.head-profilename h6 {
    color: #3c3c3c;
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 0;
}

.label-name {
    min-width: 30%;
    padding-right: 35px;
    text-align: end;
    color: #4b4b4b;
    font-weight: 700;
}

.right-item,
.input-item {
    width: 70%;
}

.input-item:focus {
    box-shadow: none;
}

.title {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
}

.subhead {
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 24px;
    border-bottom: 2px solid #dedede;
    display: inline-block;
    padding-bottom: 6px;
}

span.explaination {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #1e90ff;
    vertical-align: middle;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 8px;
}

.click-statement+label {
    border: 1px solid #999;
    color: #999;
    height: 35px;
    width: 35px;
    text-align: center;
    border-radius: 50%;
    padding: 5px;
    position: relative;
    font-weight: 700;
}

.click-statement+label::after {
    content: "";
    position: absolute;
    border-bottom: 4px solid;
    bottom: -4px;
    width: 100%;
    left: 0px;
    height: 100%;
    border-radius: 50%;
}

.click-statement[type="radio"]:checked+label {
    border: 1px solid #111;
    color: #333;
    background: #eee;
}

.click-statement[type="radio"]:checked+label:after {
    content: "";
    position: absolute;
    bottom: -4;
    border-bottom: 4px solid #222;
}

.click-statement2+label {
    position: relative;
    padding: 30px 10px;
    text-align: inherit;
    border-radius: 10px;
}

.click-statement2+label::after {
    content: "";
    position: absolute;
    border-bottom: 5px solid grey;
    bottom: -5px;
    width: 100%;
    left: 0px;
    height: 100%;
    border-radius: 11px;
}

.click-statement2[type="radio"]:checked+label {
    background: #eee;
}

.select-option {
    position: relative;
    max-width: 100%;

    // width: auto;
    & .right-answer {
        color: var(--green);
        border-radius: 11px;
        border: 2px solid var(--green) !important;
    }

    & .wrong-answer {
        color: red !important;
        border-radius: 11px;
        border: 2px solid red !important;
    }

    & .answer-response {
        position: absolute;
        bottom: 7px;
        right: 20px;
    }
}

.query-place-div {
    & .query-place-checked {
        & .inner-span {
            pointer-events: none;

            &::after {
                display: none;
            }

            & .icon {
                display: none;
            }
        }

        &.half-drag {
            .inner-span {
                border-radius: 0 8px 8px 0;
                //min-width: 110px;
            }
        }

        &.multi-drop {
            .inner-span {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border: 2px solid #bababa;
                border-radius: 8px;
                vertical-align: middle;
                position: relative;
                color: #5c5c5c;
                cursor: pointer;
                min-width: 60px;
            }
        }
    }
}

.query-place-checked {
    .inner-span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #bababa;
        border-radius: 8px;
        vertical-align: middle;
        position: relative;
        color: #5c5c5c;
        cursor: pointer;
        white-space: nowrap;
    }

    &.half-drag .inner-span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #bababa;
        border-radius: 0 8px 8px 0;
        vertical-align: middle;
        position: relative;
        color: #5c5c5c;
        cursor: pointer;
        white-space: nowrap;
    }

    &.multi-drop {
        .inner-span {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border: 2px solid #bababa;
            border-radius: 8px;
            vertical-align: middle;
            position: relative;
            color: #5c5c5c;
            cursor: pointer;
            white-space: nowrap;
        }
    }
}

.highlighted {
    .query-place {
        border-color: var(--blue);
    }
}

.disable-drag-option {
    pointer-events: none !important;
    opacity: 0.4 !important;
}

/* Hide number input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.footer-link.ps-mob-pd:hover {
    border-bottom: 0px solid transparent;
}

.footer-link.ps-mob-pd:hover span {
    border-bottom: 0px solid transparent;
}

.footer-link.float-end.ps-mob-pd a:hover {
    border-bottom: 1px solid #fff !important;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.question-box-exam {
    max-height: 100%;
    overflow-y: auto;
}

.no-p-margin-bottom {
    p {
        margin-bottom: 0px !important;
    }

    .cke_editable_inline {
        min-height: 45px;
    }

    &.reasoning-height {
        .cke_editable_inline {
            min-height: 75px;
        }
    }
}

.exam-progress {
    position: fixed;
    max-width: inherit;
    width: 100%;
    z-index: 3;
}

.exam-sidebar {
    position: fixed;
    max-width: inherit;
    width: 100%;
}

@media (max-width: 576px) {
    .triangle {
        left: 60%;
        top: 94%;
    }

    .mth-4 {
        margin-top: 10px;
    }

    .mth-5t {
        margin-top: 15px;
    }
}

.cbg-secondary {
    background: #e5e5e6 !important;
    color: rgb(33, 32, 32) !important;
}

.cbg-success {
    background: linear-gradient(90deg, #00d7734f, #00d773c7 99%);
    color: #fff !important;
}

.cbg-info {
    color: #ffffff !important;
    background: linear-gradient(90deg, #00d77361, #008ffacc);
}

.cbg-warning {
    background: #ff9f43 !important;
    color: white !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

#exTab1 .tab-content {
    color: white;
    background-color: #428bca;
    padding: 5px 15px;
}

#exTab2 h3 {
    color: white;
    background-color: #428bca;
    padding: 5px 15px;
}

#exTab1 .nav-pills>li>a {
    border-radius: 0;
}

#exTab3 .nav-pills>li>a {
    border-radius: 4px 4px 0 0;
}

#exTab3 .tab-content {
    color: white;
    background-color: #428bca;
    padding: 5px 15px;
}

.badge-soft-success {
    color: #0ab39c;
    background-color: rgba(10, 179, 156, 0.1);
}

.badge-soft-warning {
    color: #f7b84b;
    background-color: rgba(247, 184, 75, 0.1);
}

.badge-soft-danger {
    color: #f06548;
    background-color: rgba(240, 101, 72, 0.1);
}

.btn-clipboard {
    padding: 0.1rem 0.4rem;
    font-size: 0.5em !important;
    background-color: #fff;
    border: 1px solid;
    border-radius: 0.25rem;
}

.btn-soft-info {
    color: #299cdb;
    background-color: rgba(41, 156, 219, 0.1);
    border-color: transparent;
}

.btn-soft-info:active,
.btn-soft-info:focus,
.btn-soft-info:hover {
    color: #fff;
    background-color: #299cdb;
}

.btn-soft-success {
    color: #00d773;
    background-color: #fff;
}

.btn-soft-success:active,
.btn-soft-success:hover,
.btn-soft-success:focus {
    color: #fff;
    background-color: #00d773;
}

.card-title {
    font-size: 16px;
    margin: 0 0 7px 0;
}

.card-height-100 {
    height: calc(100% - 1.5rem);
}

.card-height-110 {
    height: calc(100% - 0.1rem);
}

.short-sammery-table .header-title,
.custom-card-title {
    font-size: 1.1em;
    margin: 0 0 7px 0;
}

.short-sammery-table table tbody td {
    padding: 4px 5px;
}

.short-sammery-table table tbody td {
    font-size: 0.8rem !important;
}

.bordered .table thead {
    color: #212529;
    border-color: #e9ebec;
    background-color: #f3f6f9;
}

.bordered .table> :not(caption)>*>* {
    padding: 0.75rem 0.6rem;
    background-color: var(--vz-table-bg);
    border-bottom-width: 1px;
    -webkit-box-shadow: inset 0 0 0 9999px #00000000;
    box-shadow: inset 0 0 0 9999px #00000000;
}

.stat-cards-icon-success {
    background-color: rgb(10, 179, 156, 25%) !important;
}

.img-card .card-img-absolute {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
}

.img-card i {
    background-color: rgba(47, 73, 209, 0.1);
    padding: 13px 12px;
    border-radius: 40px;
    height: 60px;
    width: 60px;
    color: #fff;
    font-size: 2rem;
    line-height: 35px;
}

.img-card i:hover {
    background-color: #00d77300;
    box-shadow: 0 1px 6px 2px rgb(10, 179, 156, 25%) !important;
}

.main-chart .chart-border-left {
    border-left: 1.4px solid var(--vz-border-color);
    padding: 2px 20px;
}

.head-black table.table thead th span {
    color: #000;
}

.head-black table.table thead th span::after {
    color: var(--blue);
}

.error-button-msg .invalid-feedback {
    color: #fff !important;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef !important;
    opacity: 1;
}

.move-rocket {
    width: 400px;
    height: 400px;
    position: absolute;
    animation: moverocket 5s linear forwards;
    bottom: 0;
    left: 0;
}

@keyframes moverocket {
    0% {
        left: -200px;
        bottom: -200px;
        transform: translate(0, 0) rotate(0deg);
    }

    40% {
        left: 40%;
        bottom: 40%;
        transform: translate(calc(50% - 200px), calc(50% - 200px)) rotate(0);
    }

    41% {
        left: 40%;
        bottom: 40%;
        transform: translate(calc(50% - 200px), calc(50% - 200px)) rotate(0);
    }

    61% {
        left: 40%;
        bottom: 40%;
        transform: translate(calc(50% - 200px), calc(50% - 200px)) rotate(-360deg);
    }

    100% {
        left: 100%;
        bottom: 100%;
        transform: translate(calc(100% - 200px), calc(100% - 200px)) rotate(-360deg);
    }
}

// @media (min-width: 1400px){
//     .grid-css_desktop .grid-desk {
//         width: 33.33333333%;
//     }
// }

@media only screen and (max-width: 991px) {
    #asideMobile {
        display: block !important;
        margin-left: 0 !important;

        & button[data-bs-target="#restartModal"] {
            display: none;
        }

        & #quitButtonAside {
            display: none;
        }
    }

    .exam-sidebar {
        z-index: 3;
    }

    #testimonialImage input#testimonial_image {
        padding: 0.2rem 0rem;
        text-transform: initial;
    }
}

div#result-bg .amazing_work {
    height: 100%;
}

div#result-bg .amazing_work img {
    padding-top: 237px;
    width: 49.7%;
}

.form-check-label.fw-bold.privacy-check::after {
    content: "*";
    position: absolute;
    color: red;
    margin-left: 3px;
}

.form-check-label.fw-bold.privacy-check {
    position: relative;
}

.raw-question {
    .input-field-mini {
        padding: 0 4px;
        margin: 8px 0;
        border-radius: 0.275rem;
    }

    .select-field-mini {
        padding: 0 18px 0 4px;
        margin: 8px 0;
        background-position: right 1px center;
        border-radius: 0.275rem;
    }

    .image-mini {
        width: 17px;
    }

    sup {
        top: -0.6em;
    }

    sub {
        bottom: -0.6em;
    }
}

.pe-none2 {
    opacity: 0.6;
    pointer-events: none;
}

body.loading-data {
    pointer-events: none;
}

body.loading-data::before {
    content: "";
    position: fixed;
    border-radius: 20px;
    left: 0;
    background: -webkit-gradient(linear, right top, left top, from(var(--green)), to(#ffc107)) !important;
    background: linear-gradient(to left, var(--green), #ffc107) !important;
    width: 0;
    height: 3px;
    z-index: 9999;
    -webkit-animation: tableloadingBar 4s linear infinite;
    animation: tableloadingBar 4s linear infinite;
}

@-webkit-keyframes tableloadingBar {
    0% {
        width: 0%;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}

@keyframes tableloadingBar {
    0% {
        width: 0%;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}

#testimonialImage input[type="file"]::-webkit-file-upload-button {
    min-height: calc(1.5em + 1rem + 4px);
    padding-left: 25px;
}

#testimonialImage input#testimonial_image {
    padding: 0.2rem 0rem;
}

.wrs_tickContainer {
    display: none !important;
}

.sticky-header .page-head {
    position: sticky;
    width: 100%;
    top: 0;
    background: #fff;
    z-index: 9;
    padding: 20px 0 0 0;
}

#calculatorModal {

    // overflow: hidden;
    .modal-dialog {
        // position: relative;
        // width: auto;
        // margin: var(--bs-modal-margin);
        // pointer-events: none;
        // left: 0%;
        // top: 0%;
    }
}

div#button_lineheigh span {
    line-height: 29px;
}

/** canculatot remove scroler **/


select#course_type,
select#year_level,
select.form-select {
    -webkit-appearance: none !important;
}

.question-box-exam span#question_box p input {
    min-width: 50px;
    width: 100%;
    text-align: center;
}

@media (min-width: 576px) {
    #calculatorModal .modal-dialog {
        max-width: 470px;
        margin-right: 20px;
        margin-left: auto;
    }
}

#side_scrooling {
    overflow-y: auto;
    max-height: 80vh;
    height: 100%;
}

.product_left_slide .slick-track {
    margin-left: unset;
}

/** popup Model open **/

@media screen and (min-width: 992px) {
    .left_arrow.modal-dialog {
        margin-left: 40px;
    }

    .right_arrow.modal-dialog {
        margin-right: 40px;
    }
}

/** popup Model end**/

.progress-sticky {
    position: sticky;
    top: 92px;
    z-index: 10;
    background: #ffffff;
    padding-bottom: 4px;
}

.body-overflowhidden {
    .progress-sticky {
        z-index: 2;
    }
}

/*------------Slider--------------------------*/


@media (max-width: 992px) {
    .account-ul {
        max-width: 0px !important;
    }

    .width_selecto {
        max-width: 100px;
        min-width: 100px;
        width: 100%;
    }

    .slick-common-right {
        display: none;
    }

    .slick-visible-arrow .slick-common-left {
        display: none;
    }

    #cart-item-mobile-count {
        height: 16px;
        width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        padding: 0px 0 0 0;
        line-height: 0;
    }

    .sticky-header .sticky_top_padding {
        padding-top: 70px;
    }

    // .popover {
    //     width: 100%;
    //     object-fit: cover;
    //     // overflow: hidden;

    // }
    .margin-img-top-res {
        margin-top: 20px;
    }

    .card-skybar.ms-auto.margin-img-top-res {
        width: 100%;
        max-width: unset;
        padding-right: 0;
    }

    .shadow-none.z-index-responsive-top {
        z-index: 3 !important;
    }

    div#footerk {
        padding-left: 30px;
        padding-top: 30px;
    }

    .p-padding-responsive {
        margin-left: 13px;
    }

    .content-width_heading h5 {
        padding-top: 10px;
    }

    .arrow-right_left {
        display: none;
    }

    div#result-bg .amazing_work img {
        padding-top: 0;
        width: 49.7%;
    }

    #testimonialImage input#testimonial_image {
        text-transform: capitalize;
    }

    .nav-tabs .pading-top-ress .nav-bar .nav-link {
        font-size: 20px;
    }

    form#addHomeContent h4 {
        font-size: 20px;
    }

    form#aboutUsForm h4 {
        font-size: 20px;
    }
}

.question img {
    max-width: 100%;
}

.btn-del_spacing {
    margin: 0.9px;
}

@media (max-width: 480px) {
    .form-floating {
        position: relative;
        margin: 11px 0 0 0px;
    }
}

@media (max-width: 416px) {
    .small-fontsize {
        font-size: 20px;
    }
}

@media (max-width: 380px) {
    .small-fontsize {
        font-size: 16px;
    }

    .mt-check {
        margin-top: 20px;
    }

    .fs-32 {
        font-size: 21px !important;
    }

    span#cart-item-mobile-count {
        font-size: 9px !important;
        height: 14px !important;
        width: 14px;
        padding: 2px;
    }

    .listing-small-text span {
        font-size: 14px;
    }

    .listing-heading {
        font-size: 18px;
    }

    .workbook-heading-smallsize h4 {
        font-size: 18px;
    }

    .product-detail-heading {
        font-size: 18px;
    }

    .small {
        font-size: 11px;
    }

    // .d-icon-all span {
    //     font-size: 11px;
    // }
    .small {
        font-size: 11px;
    }

    section#respon-sec h2,
    section#respon-sec .h2 {
        line-height: 28px;
        font-size: 24px;
    }

    .p-small-size {
        font-size: 16px !important;
    }

    .checkbox_agree_condition .form-check-custom .form-check-label {
        padding-top: 2px;
        font-size: 15px;
    }
}

/** only use ipad **/
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .Product_details_bottom {
        padding-top: 120px;
    }

    .practice-exam .pill {
        left: -33px;
    }

    .signup-unlock .signup-pill {
        left: 1px;
        top: -38px;
    }

    .practice-exam .circle {
        left: 130px !important;
    }

    .signup-unlock .signup-circle {
        right: -11px !important;
        width: 100px;
        height: 100px;
        top: 109px;
    }

    footer {
        padding-left: 13px;
    }

    .triangle.border.border-blue.rounded.border-2.mmwh-44.position-absolute {
        left: 94%;
    }

    .sticky-header .sticky_top_padding {
        padding-top: 70px;
    }

    .pagination-sm .page-item .page-link {
        height: 35px;
        min-width: 35px;
        margin-left: 3px;
    }

    .ipad_responsive_model .modal-dialog {
        max-width: 90%;
        width: 100%;
    }

    .arrow-right_left {
        display: block;
    }

    .body-over_flowx {
        overflow-x: hidden;
    }

    footer .footer-link {
        display: unset;
        margin-top: 3px;
    }

    button#backspace {
        font-size: 24px;
        text-align: center;
        padding-left: 20px;
    }
}

@media only screen and (min-width: 599px) and (max-width: 1138px) {
    div#flex-social_ipad .flex-md-row {
        flex-direction: row !important;
    }

    .signup-unlock .signup-circle {
        right: 66px;
    }

    .signup-unlock .signup-pill {
        left: 5px;
    }

    .practice-exam .circle {
        left: 277px;
    }

    .canculator_font-size {
        font-size: 14px;
    }
}

@media (max-width: 680px) {
    .pagination-sm .page-item .page-link {
        height: 40px;
        min-width: 40px;
        text-decoration: none;
        display: inline-flex;
        border: 1px solid #dee2e6;
        margin-left: 5px;
        cursor: pointer;
        text-align: center;
        justify-content: center;
        align-items: center;
        border-radius: 0.3rem;
        font-size: 16px;
        color: #212529;
    }

    .pagination-sm .page-item.next .page-link,
    .pagination-sm .page-item.previous .page-link {
        font-weight: 500;
        width: 73px;
    }

    .text-ellips_heading {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: normal;
        width: 170px;
    }

    .page-head .btn {
        font-size: 15px;
        line-height: 14px;
    }

    .btn-widthh0mhn {
        min-width: 142px;
    }
}

@media (max-width: 580px) {
    .pagination-sm .page-item .page-link {
        align-items: center;
        border: 1px solid #dee2e6;
        border-radius: 0.3rem;
        color: #212529;
        cursor: pointer;
        display: inline-flex;
        font-size: 14px;
        height: 24px;
        justify-content: center;
        margin-left: 2px;
        min-width: 24px;
        text-align: center;
        text-decoration: none;
    }

    .pagination-sm .page-item.next .page-link,
    .pagination-sm .page-item.previous .page-link {
        font-weight: 500;
        width: 60px;
    }

    // Today trial

    .card-skybar .card-innerbar {
        display: flex;
        align-items: center;
        background-color: var(--sky);
        border-radius: 16px;
        overflow: hidden;
        position: relative;
        flex-direction: column-reverse;
    }

    .card-skybar img {
        align-self: flex-start;
        height: 130px;
        pointer-events: none;
    }

    .card-limebar .card-innerbar {
        display: flex;
        align-items: center;
        background-color: var(--lime);
        border-radius: 16px;
        overflow: hidden;
        position: relative;
        flex-direction: column;
    }

    .footer-padding {
        padding: 0 0px 0 44px;
    }

    // End
    .label_top_margin {
        margin-bottom: 20px;
    }

    .page-head .h3 {
        font-size: 20px;
        line-height: 20px;
    }
}

@media screen and (max-width: 767px) {
    section#trangle_bottom .triangle {
        left: 31% !important;
        top: 92% !important;
    }

    .margin_bottom_responsive {
        margin-bottom: 12px;
    }

    form#addWorkbookContent {
        padding: 10px;
    }

    .button_font {
        text-align: right;
    }

    .question .incorrect_text_es {
        line-height: 32px;
        font-size: 14px !IMPORTANT;
    }

    div#footerk .top-pading {
        padding-top: 11px;
    }

    footer {

        .footer-social,
        .footer-slogan {
            padding: 0 0 0 10px;
        }

    }

    div#footerk .footer-link,
    .footer-link-copyright {
        display: block;
        padding-left: 17px;
        width: 100%;
    }

    .margin-img-top-res .card-innerbar {
        flex-direction: row-reverse;
    }

    .f-28_size {
        font-size: 28px;
    }

    .tec_btn-left {
        margin-right: 14px;
    }

    .small_dev-end button.btn {
        float: right;
    }

    .mfont-size {
        font-size: 15px;
    }

    .pading-top-ress {
        margin-top: 10px;

    }

    .body-over_flowx {
        overflow-x: hidden;
    }

    .ps-mob-pd {
        padding-top: 20px;
    }

    div#workbookBox {
        overflow-x: hidden;
    }

    .nav-bar .nav-link {
        font-size: 18px;
    }

    .overlay-bgbl-exam {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        border-radius: 0;
    }
}

@media (max-width: 575px) {
    .hm-180 {
        height: 180px !important;
    }

    .up-date-g .margin_bottom_responsivet {
        margin-bottom: 10px;
    }

    .button_font {
        .mt-check-small {
            margin-right: 14px;
            margin-top: 10px;
        }
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1530px) {
    div#flex_resp_dels {
        .mmwh-160 {
            min-width: 110px;
            max-width: 110px;
            min-height: 110px;
            max-height: 110px;
        }

        .mmwh-48 {
            min-width: 40px;
            max-width: 40px;
            min-height: 40px;
            max-height: 40px;
        }

        // .content-width_heading {
        //     min-width: 110px;
        //     padding-left: 10px;
        // }
    }
}
@media only screen and (min-width: 575px) and (max-width: 1095px) {
.manage_sign_guest_btn{
    flex-direction: column !important;
    align-items: start;
    .btn{
        width: auto !important;
    }
}
}
@media only screen and (min-width: 1024px) and (max-width: 1159px) {
    div#flex_resp_dels {
        .mmwh-160 {
            min-width: 110px;
            max-width: 110px;
            min-height: 110px;
            max-height: 110px;
        }

        .mmwh-48 {
            min-width: 40px;
            max-width: 40px;
            min-height: 40px;
            max-height: 40px;
        }

    }

    .footer-address,
    p.copyright {
        color: #000;
        font-size: 11px !important;
        margin-bottom: 0;
        font-weight: 500;
    }
}

/** roate device only ***/

@media (max-width: 780px) {
    div#allquestions .tab_width-t {
        overflow-x: scroll;
        max-width: 100% !important;
    }

    .footer-address,
    p.copyright {
        color: #000;
        font-size: 11px !important;
        margin-bottom: 0;
        font-weight: 500;
    }

}

/** roate device ***/

@media only screen and (min-width: 375px) and (max-width: 767px) {
    button#backspace {
        padding: 0 8px 0 8px;
    }

    div#allquestions .tab_width-t {
        overflow-x: scroll;
        max-width: 343px;
    }

    .nav-bar .nav-link {
        font-size: 18px;
    }

    .footer-address,
    p.copyright {
        color: #000;
        font-size: 11px;
        margin-bottom: 0;
        font-weight: 500;
    }

    .main-copyright.py-4 {
        display: flex;
        justify-content: center !important;
        align-items: center;
    }
}
@media (max-width: 575px) {
.home-section1 .homebg-cover {
    
    height: 180%;
  
}
}
@media (max-width: 480px) {
    .home-section1 .homebg-cover {
        height: 159%;
    }
    .boy_img{
        transform: translateY(0px) !important;
    }
    .hey-man .circle1 {
        top: 14% !important;
    }
    .hey-man .circle2 {
        top: 20%;
    }
    }
    @media (max-width: 450px) {
        .home-section1 .homebg-cover {
            height: 159%;
        }
        }
@media (max-width: 414px) {

    .home-section3 .practice-exam .circle {
        left: 156px;
    }

    div#allquestions .tab_width-t {
        overflow-x: scroll;
        max-width: 355px;
    }

    .signup-unlock .signup-circle {
        width: 122px;
        height: 122px;
        bottom: -49px;

    }

    .overfloe_email label {
        font-size: 15px;
    }

    .main-copyright {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
    }
}

@media (max-width: 380px) {
    .pagination-sm .page-item .page-link {
        align-items: center;
        border: 1px solid #dee2e6;
        border-radius: 0.3rem;
        color: #212529;
        cursor: pointer;
        display: inline-flex;
        font-size: 12px;
        height: 30px;
        justify-content: center;
        margin-left: 1px;
        min-width: 22px;
        text-align: center;
        text-decoration: none;
    }

    .pagination-sm .page-item.next .page-link,
    .pagination-sm .page-item.previous .page-link {
        font-weight: 500;
        width: 57px;
    }

    div#allquestions {
        width: 386px;
    }

    .scrool_width_responsive div#allquestions {
        width: 100%;
    }

    .tab-content form#contentForm div#allquestions {
        width: auto;
    }

    .button_font {
        text-align: right;

        .form-select-md {
            font-size: 12px;
            padding-left: 6px;
            min-height: 17px;
            line-height: 12px;

            strong {
                font-size: 12px;
            }

            button.btn {
                font-size: 12px;
                padding: 6px;
            }
        }
    }

    .signup-unlock .signup-circle {
        width: 122px;
        height: 122px;
        bottom: -49px;

    }

    .revers-colum .modal-footer {
        flex-direction: column-reverse;
    }

    .ipad_responsive_model .modal-footer {
        flex-direction: column-reverse;
    }

    .main-copyright {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
    }
}

@media screen and (max-width: 320px) {
    .my-sm-3 {
        margin: 14px 0;
    }

    .practice-exam .pe-card.pr-cardend {
        margin-left: auto;
    }

    .question-box-exam .question {
        button.btn {
            font-size: 16px;
        }
    }

    .margin_bottom_responsivet {
        margin-bottom: 10px;
    }

    .page-head .btn {
        font-size: 13px;
        line-height: 14px;
    }

    div#allquestions .tab_width-t {
        overflow-x: scroll;
        max-width: 275px;
    }

    .mod-calculator button {
        padding: 0;
        margin: 0;
    }

    .small_dev-end button.btn {
        float: right;
    }

    .overfloe_email label {
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        padding-bottom: 45px;
        padding-top: 4px;
    }

    .main-copyright {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
    }
}

@media (max-width: 280px) {
    .small-fontsize {
        font-size: 13px;
    }
}

.scale-label {
    opacity: 1;
    font-size: 12px !important;
    transform: scale(1) translateY(-1.9rem) translateX(0);
}

.assignment-expired {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
}

.zoom-text {
    color: red;
    animation: zoom-in-zoom-out 4s ease infinite;
}

.main-copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.1, 1.1);
    }

    100% {
        transform: scale(1, 1);
    }
}

.exam-red {
    background-color: #ffcccc;
    border-radius: 3px;
    transition: 0.3s;
}

.exam-yellow {
    background-color: #ffffb3;
    border-radius: 3px;
    transition: 0.3s;
}

.exam-green {
    background-color: #c1f0c1;
    border-radius: 3px;
    transition: 0.3s;
}

.exam-green:hover,
.exam-yellow:hover,
.exam-red:hover {
    box-shadow: 4px 4px 7px #ccc !important;
    transition: 0.3s;
}

.anaystic-table {
    overflow: auto;
    max-height: 70vh;
}

.anaystic-table thead {
    position: sticky;
    top: -1px;
    background: linear-gradient(245deg, #1e90ff -1.64%, #12d76c 100%);
    z-index: 9999;
}

p.copyright {
    font-size: 13px;
    color: #fff;
    margin-bottom: 0px;
}

.footer-address {
    font-size: 13px;
    color: #fff;
    border-right: 1px solid;
    margin-bottom: 0px;
    line-height: 13px;
}

.footer-main {
    background: #05b656;
}

.footer-address,
p.copyright {
    color: #fff;
    font-size: 13px;
    margin-bottom: 0;
    font-weight: 500;
}

.abn {
    color: #fff;
    font-size: 12px;
    margin-bottom: 0;
    font-weight: 500;
}

.abn-p {
    padding: 0 0 0 3px;
}

.main-copyright {
    padding: 0px 6px 0 0px;
}

.main-copyright.py-4 {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 2px;
}

.home-sec-content {
    margin-right: -150px;
    margin-left: 150px;
  }

@media (max-width:1399.98px){ 
.home-sec-content {
    margin-right: -100px;
    margin-left: 100px;
  }
}
@media (max-width:1299.98px){ 
.home-sec-content {
    margin-right: -60px;
    margin-left: 60px;
    }
}

@media (max-width:1199.98px){ 
.home-sec-content {
    margin-right: 0px;
    margin-left: 0px;
    }
}